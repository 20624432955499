import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer } from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import TripsTable from '../components/Trips/TripsTable';

export const Trips = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const FreightseekToken = localStorage.getItem('FreightseekToken');
		if (!FreightseekToken) {
			navigate('/login');
		}
	}, [navigate]);

	return (
		<div>
			<div>
				<Navbar />
			</div>
			<div className="min-h-[100vh]">
				<TripsTable />
			</div>
			<div>
				<Footer />
			</div>
		</div>
	);
};
