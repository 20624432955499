import { Button, Card, CardActionArea, CardActions, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import { Footer } from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import admin from '../../img/admin.jpg';
import customer from '../../img/customer.jpg';
import transporter from '../../img/transporter.jpg';

export const Users = () => {
	return (
		<div>
			<div className="min-h-[92vh] bg-slate-50">
				<Navbar />
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						minHeight: '87vh',
						gap: '20px',
						flexWrap: 'wrap',
						padding: '20px',
					}}
				>
					<Link to="/customers">
						<Card sx={{ maxWidth: 345 }}>
							<CardActionArea>
								<CardMedia component="img" height="120" image={customer} alt="Customers photo" />
								<CardActions className="flex justify-center">
									<Button size="large">Admin Customers</Button>
								</CardActions>
							</CardActionArea>
						</Card>
					</Link>
					<Link to="/transporters">
						<Card sx={{ maxWidth: 345 }}>
							<CardActionArea>
								<CardMedia component="img" height="120" image={transporter} alt="Customers photo" />
								<CardActions className="flex justify-center">
									<Button size="large">Admin Transporters</Button>
								</CardActions>
							</CardActionArea>
						</Card>
					</Link>
					<Link to="/adminpannel">
						<Card sx={{ maxWidth: 345 }}>
							<CardActionArea>
								<CardMedia component="img" height="120" image={admin} alt="Customers photo" />
								<CardActions className="flex justify-center">
									<Button size="large">Admin Pannel</Button>
								</CardActions>
							</CardActionArea>
						</Card>
					</Link>
				</div>
			</div>
			<Footer />
		</div>
	);
};
