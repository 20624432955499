import InstantFreightsTable from '../components/InstantFreights/InstantFreightsTable';
import HomeLayout from '../components/Layout/HomeLayout';

export const InstantFreights = () => {
	return (
		<HomeLayout>
			<InstantFreightsTable />
		</HomeLayout>
	);
};
