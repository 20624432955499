import { numberInputClasses } from '@mui/base/Unstable_NumberInput';
import { blue, grey } from '@mui/material/colors';
import { darken, lighten, styled, Theme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

const getBackgroundColor = (color: string, theme: Theme, coefficient: number) => ({
	backgroundColor: darken(color, coefficient),
	...theme.applyStyles('light', {
		backgroundColor: lighten(color, coefficient),
	}),
});

const getBorderColor = (color: string, theme: Theme) => ({
	borderColor: darken(color, 0.5), // Darken the border slightly compared to the background
});

export const customGrey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025',
};

export const StyledInputRoot = styled('div')(
	({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? customGrey[300] : customGrey[900]};
  background: ${theme.palette.mode === 'dark' ? customGrey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? customGrey[700] : customGrey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? customGrey[900] : customGrey[50]};
  display: grid;
  grid-template-columns: 1fr 19px;
  grid-template-rows: 1fr 1fr;
  overflow: hidden;
  column-gap: 8px;
  padding: 4px;

  &.${numberInputClasses.focused} {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus-visible {
    outline: 0;
  }
`,
);

export const StyledInputElement = styled('input')(
	({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5;
  grid-column: 1/2;
  grid-row: 1/3;
  color: ${theme.palette.mode === 'dark' ? customGrey[300] : customGrey[900]};
  background: inherit;
  border: none;
  border-radius: inherit;
  padding: 8px 12px;
  outline: 0;
`,
);

export const StyledButton = styled('button')(
	({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 19px;
  height: 19px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 0;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  transition: all 120ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
  }

  &.${numberInputClasses.incrementButton} {
    border-radius: 4px 4px 0 0;
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    &:hover {
      background: ${blue[400]};
      color: ${grey[50]};
    }
  }

  &.${numberInputClasses.decrementButton} {
    border-radius: 0 0 4px 4px;
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    &:hover {
      background: ${blue[400]};
      color: ${grey[50]};
    }
  }
`,
);

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	'& .super-app-theme--0': {
		...getBackgroundColor(theme.palette.warning.main, theme, 0.7),
		'&:hover': {
			...getBackgroundColor(theme.palette.warning.main, theme, 0.6),
		},
		'&.Mui-selected': {
			...getBackgroundColor(theme.palette.warning.main, theme, 0.5),
			'&:hover': {
				...getBackgroundColor(theme.palette.warning.main, theme, 0.4),
			},
		},
	},
	'& .super-app-theme--2': {
		...getBackgroundColor(theme.palette.success.main, theme, 0.7),
		'&:hover': {
			...getBackgroundColor(theme.palette.success.main, theme, 0.6),
		},
		'&.Mui-selected': {
			...getBackgroundColor(theme.palette.success.main, theme, 0.5),
			'&:hover': {
				...getBackgroundColor(theme.palette.success.main, theme, 0.4),
			},
		},
	},
	'& .super-app-theme--1': {
		...getBackgroundColor(theme.palette.info.main, theme, 0.7),
		'&:hover': {
			...getBackgroundColor(theme.palette.info.main, theme, 0.6),
		},
		'&.Mui-selected': {
			...getBackgroundColor(theme.palette.info.main, theme, 0.5),
			'&:hover': {
				...getBackgroundColor(theme.palette.info.main, theme, 0.4),
			},
		},
	},
	'& .super-app-theme--3': {
		...getBackgroundColor(theme.palette.grey[400], theme, 0.7),
		'&:hover': {
			...getBackgroundColor(theme.palette.grey[400], theme, 0.6),
		},
		'&.Mui-selected': {
			...getBackgroundColor(theme.palette.grey[400], theme, 0.5),
			'&:hover': {
				...getBackgroundColor(theme.palette.grey[400], theme, 0.4),
			},
		},
	},
	// Border styles for the status column
	'& .super-app-border--0': {
		...getBorderColor(theme.palette.info.main, theme),
		borderWidth: '1px',
		borderStyle: 'solid',
	},
	'& .super-app-border--1': {
		...getBorderColor(theme.palette.info.main, theme),
		borderWidth: '1px',
		borderStyle: 'solid',
	},
	'& .super-app-border--2': {
		...getBorderColor(theme.palette.warning.main, theme),
		borderWidth: '1px',
		borderStyle: 'solid',
	},
	'& .super-app-border--3': {
		...getBorderColor(theme.palette.grey[400], theme),
		borderWidth: '1px',
		borderStyle: 'solid',
	},
}));
