import HomeLayout from '../components/Layout/HomeLayout';
import { ReviewsTable } from '../components/Reviews/ReviewsTable';

export const Reviews = () => {
	return (
		<HomeLayout>
			<ReviewsTable />
		</HomeLayout>
	);
};
