import { RenderDrawerCarrierContent } from './DrawerCarrier';
import { RenderDrawerCustomerContent } from './DrawerCustomer';

export const RenderDrawerContent = (drawerContent: any, detailsType: any) => {
	const user = drawerContent?.user;

	return (
		<>
			{detailsType === 'carrier'
				? RenderDrawerCarrierContent(drawerContent, detailsType, user)
				: RenderDrawerCustomerContent(drawerContent, detailsType, user)}
		</>
	);
};
