import { PieChart } from '@mui/x-charts';
import { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { QuoteVsBookingsProps } from '../../types/types'; // Adjust the path as needed

export const QuoteVsBookings: React.FC<QuoteVsBookingsProps> = ({
	freights,
	bookings,
	cancelled,
	completed,
	loading,
}) => {
	const [showInfo, setShowInfo] = useState(false);

	const handleMouseEnter = () => {
		setShowInfo(true);
	};

	const handleMouseLeave = () => {
		setShowInfo(false);
	};

	return (
		<div>
			<div className=" rounded-md bg-white rounded-b flex flex-col justify-between leading-normal shadow-md">
				<div className="pt-2 pl-4 h-10 bg-gray-800 flex justify-between rounded-t  align-middle">
					<p className="pr-2 text-white">
						{' '}
						<b> Freights vs Bookings </b>
					</p>
					<div /* onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} */ className="w-10">
						<FaInfoCircle color="white" size={20} className="mr-3" />
						{showInfo && (
							<div className="bg-white  shadow-2xl rounded-md p-2 w-72 border border-gray-300 absolute -mt-20 -ml-64">
								This graphic shows how many Freights, Bookings and Cancelled Freights were done
							</div>
						)}
					</div>
				</div>
				<PieChart
					loading={loading}
					series={[
						{
							data: [
								{ id: 0, value: freights, color: '#ead849', label: 'Freights' },
								{ id: 1, value: bookings, color: '#1878b5', label: 'Bookings' },
								{ id: 2, value: cancelled, color: 'red', label: 'Cancelled' },
								{ id: 3, value: completed, color: 'green', label: 'Completed' },
							],
						},
					]}
					width={300}
					height={150}
				/>
			</div>
		</div>
	);
};
