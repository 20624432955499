import { Box, Button, Grid, Modal, Portal } from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarQuickFilter, GridValueGetter } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import { BASE_URL } from '../../api';
import { useTokenStoreStorage, useUserIdStorage } from '../../hooks/useTokenStore';

function MyCustomToolbar(props: any) {
	return (
		<React.Fragment>
			<Portal container={() => document.getElementById('filter-panel')!}>
				<GridToolbarQuickFilter />
			</Portal>
			<GridToolbar {...props} />
		</React.Fragment>
	);
}

export const QuestionsTable = () => {
	const [data, setData] = React.useState([]); // Estado para almacenar los datos del servidor
	const [openModal, setOpenModal] = React.useState(false);
	const [selectedRow, setSelectedRow] = React.useState<any>(null); // Especifica el tipo de selectedRow
	const token = useTokenStoreStorage((s) => s.token);
	const userId = useUserIdStorage((s) => s.userId);
	const handleOpenModal = (row: any) => {
		setSelectedRow(row);
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const fetchData = async () => {
		try {
			const response = await axios.get(`${BASE_URL}admin/allQuestion`, {
				headers: {
					AccessToken: token,
					'Content-Type': 'application/json',
				},
				params: {
					user_id: userId,
				},
			});
			return response.data.data.datas;
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	// Fetching orders with react-query
	const {
		data: dataset,
		isLoading,
		error,
	} = useQuery({
		queryKey: ['orders'],
		queryFn: fetchData,
	});

	const handleDelete = async () => {
		try {
			await axios.delete(`${BASE_URL}freight/delete`, {
				headers: {
					AccessToken: token,
				},
				params: {
					user_id: userId,
					freight_id: selectedRow.id,
				},
			});
			console.log('Data deleted successfully.');
			fetchData();
			setOpenModal(false); // Cerrar modal después de la eliminación exitosa
		} catch (error) {
			console.error('Error deleting data:', error);
		}
	};

	const idValueGetter: GridValueGetter = (value, row) => {
		const id = row.id;

		// Verificar si 'id' existe y no es null
		if (id !== undefined && id !== null) {
			return id;
		} else {
			return 'undefined';
		}
	};

	// Función para obtener el valor de la columna 'status'
	const quoteIdValueGetter: GridValueGetter = (value, row) => {
		const quoteid = row.quoteid;

		// Verificar si 'status' existe y no es null
		if (quoteid !== undefined && quoteid !== null) {
			return quoteid;
		} else {
			return 'undefined';
		}
	};

	const freightidValueGetter: GridValueGetter = (value, row) => {
		const freightid = row.freightid;

		// Verificar si 'status' existe y no es null
		if (freightid !== undefined && freightid !== null) {
			return freightid;
		} else {
			return 'undefined';
		}
	};

	const quote_amountValueGetter: GridValueGetter = (value, row) => {
		const quote_amount = row.quote_amount;
		// Verificar si 'status' existe y no es null
		if (quote_amount !== undefined && quote_amount !== null) {
			return quote_amount + '€';
		} else {
			return 'undefined';
		}
	};
	const booking_feeValueGetter: GridValueGetter = (value, row) => {
		const booking_fee = row.booking_fee;
		// Verificar si 'status' existe y no es null
		if (booking_fee !== undefined && booking_fee !== null) {
			return booking_fee;
		} else {
			return 'undefined';
		}
	};

	const uemailValueGetter: GridValueGetter = (value, row) => {
		const uemail = row.uemail;
		// Verificar si 'status' existe y no es null
		if (uemail !== undefined && uemail !== null) {
			return uemail;
		} else {
			return 'undefined';
		}
	};
	const carrValueGetter: GridValueGetter = (value, row) => {
		const carr = row.carr;
		// Verificar si 'status' existe y no es null
		if (carr !== undefined && carr !== null) {
			return carr;
		} else {
			return 'undefined';
		}
	};

	const date_bookedValueGetter: GridValueGetter = (value, row) => {
		const date_booked = row.date_booked;
		// Verificar si 'status' existe y no es null
		if (date_booked !== undefined && date_booked !== null) {
			return date_booked;
		} else {
			return 'undefined';
		}
	};

	const statusValueGetter: GridValueGetter = (value, row) => {
		const status = row.status;
		// Verificar si 'status' existe y no es null
		if (status !== undefined && status !== null) {
			return [status];
		} else {
			return 'undefined';
		}
	};
	const userAgentValueGetter: GridValueGetter = (value, row) => {
		const device = row.device;
		const platform = row.platform;
		const browser = row.browser;
		// Verificar si 'status' existe y no es null
		if (device !== undefined && device !== null) {
			return [device + ' ' + platform + '' + browser];
		} else {
			return 'undefined';
		}
	};

	const columns = React.useMemo(
		() => [
			{ field: 'id', flex: 1, headerName: 'ID', width: 70, valueGetter: idValueGetter },
			{ field: 'Quote Id', flex: 1, headerName: 'Quote ID', valueGetter: quoteIdValueGetter },
			{ field: 'Freight Id', flex: 1, headerName: 'Freight ID', valueGetter: freightidValueGetter },
			{ field: 'Amount', flex: 1, headerName: 'Amount', valueGetter: quote_amountValueGetter },
			{ field: 'Booking Fee', flex: 1, headerName: 'Booking Fee', valueGetter: booking_feeValueGetter },
			{ field: 'Customer', flex: 1, headerName: 'Customer', width: 250, valueGetter: uemailValueGetter },
			{ field: 'Transporter', flex: 1, headerName: 'Transporter', width: 170, valueGetter: carrValueGetter },
			{
				field: 'Date Booked',
				flex: 1,
				headerName: 'Date Booked',
				width: 200,
				valueGetter: date_bookedValueGetter,
			},
			{ field: 'Status', flex: 1, headerName: 'Status', valueGetter: statusValueGetter },
			{ field: 'views', flex: 1, headerName: 'Views', width: 200, valueGetter: userAgentValueGetter },
			{
				field: 'action',
				flex: 1,
				headerName: 'Action',
				renderCell: (params: any) => (
					<Button variant="contained" color="error" onClick={() => handleOpenModal(params.row)}>
						Delete
					</Button>
				),
			},
		],
		[],
	);

	const gridReturn = () => (
		<Grid container spacing={2}>
			<Grid item>
				<Box id="filter-panel" />
			</Grid>
			<Grid item style={{ height: 'auto', width: '100%' }}>
				<DataGrid
					rows={dataset || []}
					columns={columns}
					slots={{ toolbar: MyCustomToolbar }}
					autoHeight={true}
					loading={isLoading}
					initialState={{
						pagination: { paginationModel: { pageSize: 5 } },
					}}
					pageSizeOptions={[5, 10, 25]}
					slotProps={{
						loadingOverlay: {
							variant: 'linear-progress',
							noRowsVariant: 'skeleton',
						},
					}}
				/>
			</Grid>
			<Modal open={openModal} onClose={handleCloseModal}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
					}}
				>
					<p className="mb-6 text-center"> Are You sure to DELETE?</p>
					<div className="flex justify-between">
						<Button onClick={handleDelete} color="error" variant="contained" sx={{ marginRight: 3 }}>
							Delete
						</Button>
						<Button onClick={handleCloseModal} color="primary" variant="contained">
							Cancel
						</Button>
					</div>
				</Box>
			</Modal>
		</Grid>
	);

	return (
		<>
			<h1 className="m-3">Questions</h1>
			<div className="m-10">{gridReturn()}</div>
		</>
	);
};
