import { Formik } from 'formik';
import { useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { BASE_URL } from '../api';
import background from '../img/bg.png';
import logo from '../img/logoActual.png';

import { Footer } from '../components/Footer/Footer';
import { useTokenStoreStorage, useUserIdStorage } from '../hooks/useTokenStore';

const Login = () => {
	const [userName, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const setToken = useTokenStoreStorage((s) => s.addToken);
	const setUserId = useUserIdStorage((s) => s.setUserId);
	const navigate = useNavigate();

	const Schema = z.object({
		email: z.string().email(),
		password: z.string(),
	});

	const userData = new FormData();
	// test comment
	userData.append('username', userName);
	userData.append('password', password);

	const loadingToastId = 'loading-toast';
	const handlePreLogin = async () => {
		setIsLoading(true);
		toast.loading('Logging in...', { id: loadingToastId });
		const data = await fetch(`${BASE_URL}prelogin`, {
			method: 'POST',
			headers: {
				'Accept-Language': 'en-US,en;q=0.8',
			},
			body: userData,
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.message === 'User token data') {
					return data.data.GUID;
				} else {
					toast.error('Invalid Credentials. Please try again');
					toast.dismiss(loadingToastId);
				}
				return null;
			})
			.catch((err) => console.log(err));
		return data;
	};

	const handleLogin = async () => {
		const guid = await handlePreLogin();

		if (guid) {
			userData.append('GUID', guid);
		}

		const data = await fetch(`${BASE_URL}login`, {
			method: 'POST',
			headers: {
				'Accept-Language': 'en-US,en;q=0.8',
			},
			body: userData,
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.message === 'User logged in successfully.') {
					setUserId(data.data.user_id);
					setToken(data.data.access_token);
					loginCheck(data.data.user_id, guid);
					toast.success('Logged in successfully');
					setIsFormSubmitted(true);
				} else if (data.message === 'This account is currently logged in.') {
					setUserId(data.data.user_id);
					loginCheck(data.data.user_id, guid);
					toast('This account is already logged in', { icon: '⚠️' });
					if (data.data.user_id !== 0) {
						loginCheck(data.data.user_id, guid);
					}
				}
			})
			.catch((err) => console.log(err))
			.finally(() => {
				toast.dismiss(loadingToastId);
				setIsLoading(false);
			});
		return data;
	};

	const loginCheck = async (userId: string, guid: string) => {
		const response = await fetch(`${BASE_URL}loginCheck?GUID=${guid}&user_id=${userId}`, {
			method: 'POST',
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.message === 'User logged in successfully.') {
					setUserId(data.data.user_id);
					setToken(data.data.access_token);
					toast.success('Logged in successfully');
					navigate('/');
					setIsFormSubmitted(true);
				}
			});
		return response;
	};

	return (
		<>
			<Toaster />

			<div
				className="h-screen flex flex-col bg-[#0022B6] items-center justify-center bg-cover"
				style={{ backgroundImage: `url(${background})` }}
			>
				<div className="flex items-center">
					<div>
						<Formik<{ email: string }>
							validationSchema={toFormikValidationSchema(Schema)}
							initialValues={{
								email: '',
							}}
							onSubmit={(values, { setSubmitting }) => {
								setSubmitting(true);
								handleLogin();
								setSubmitting(false);
							}}
						>
							{({ handleChange, values, handleBlur, submitForm, handleSubmit, errors }) => (
								<form onSubmit={handleSubmit}>
									<div className="p-8 mx-auto flex flex-col bg-white justify-center border border-b-4 rounded-xl w-[540px] ">
										<label className="mb-4 mx-auto text-3xl font-bold text-[#1889ca]">
											{' '}
											ADMIN LOGIN
										</label>

										<img src={logo} width={100} alt="logo" className="mx-auto mb-10" />
										<div className="flex flex-col items-start mx-auto">
											<div className="flex flex-col">
												<div className="pb-2">
													<input
														type="email"
														name="email"
														className="outline-none focus:ring-1 bg-white flex-1 rounded px-6 py-2 border border-gray-300 w-72"
														placeholder="Email"
														onChange={(e) => setUsername(e.target.value)}
														value={userName}
													/>
												</div>
											</div>
											<div className="pb-4 flex flex-col relative">
												<input
													type={'password'}
													name="password"
													className="outline-none focus:ring-1 bg-white flex-1 rounded px-6 py-2 border w-72"
													placeholder="Contraseña"
													onChange={(e) => setPassword(e.target.value)}
													value={password}
												/>
											</div>

											<button
												onClick={() => {
													handleLogin();
												}}
												style={{
													background: 'linear-gradient(to right, #1889CA, #0022B6)',
												}}
												className="text-white shadow w-72 rounded p-2 font-semibold"
											>
												Iniciar sesión
											</button>
											<div className="mt-2 flex">
												<Link to="/forgot-password">
													<div className="text-sm text-blue-400 hover:underline cursor-pointer mr-16">
														¿Has olvidado tu contraseña?
													</div>
												</Link>
											</div>
										</div>
									</div>
								</form>
							)}
						</Formik>{' '}
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Login;
