import { Portal } from '@mui/base/Portal';
import { Drawer } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DataGrid, GridToolbar, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { RenderDrawerContent } from '../Layout/Drawer';
import columns from './Freightstable.columns';

import { BASE_URL } from '../../api';
import { useTokenStoreStorage, useUserIdStorage } from '../../hooks/useTokenStore';
function MyCustomToolbar(props: any) {
	return (
		<React.Fragment>
			<Portal container={() => document.getElementById('filter-panel')!}>
				<GridToolbarQuickFilter />
			</Portal>
			<GridToolbar {...props} />
		</React.Fragment>
	);
}

export default function FreightsTable() {
	const [openDrawer, setOpenDrawer] = useState(false); // Single drawer state
	const [drawerContent, setDrawerContent] = useState<any>(null); // Content to show in drawer
	const [detailsType, setDetailsType] = useState<'carrier' | 'customer' | null>(null); // Track if it's carrier or customer details

	const token = useTokenStoreStorage((s) => s.token);
	const userId = useUserIdStorage((s) => s.userId);

	// Fetch function for freights
	const fetchFreights = async () => {
		const response = await axios.get(`${BASE_URL}admin/allFreight`, {
			headers: {
				AccessToken: token,
				'Content-Type': 'application/json',
			},
			params: {
				user_id: userId,
			},
		});
		return response.data.data.datas; // Assuming this is the correct path to freight data
	};

	// Fetching freights with react-query
	const {
		data: dataset,
		isLoading,
		error,
		refetch,
	} = useQuery({
		queryKey: ['freights'], // Unique key for this query
		queryFn: fetchFreights,
	});

	const handleDetailsButton = async (type: 'carrier' | 'customer', id: string) => {
		setDetailsType(type);
		setOpenDrawer(true);

		try {
			const endpoint = type === 'carrier' ? 'carrier-details' : 'customer-details';
			const response = await axios.get(`${BASE_URL}admin/${endpoint}`, {
				headers: {
					AccessToken: token,
					'Content-Type': 'application/json',
				},
				params: {
					user_id: userId,
					[`${type}_id`]: id,
				},
			});
			setDrawerContent(response.data.data); // Store details in the drawer content
		} catch (error) {
			toast.error(`Error fetching ${type} details`);
		}
	};

	const handleCloseDrawer = () => {
		setOpenDrawer(false);
		setDrawerContent(null);
		setDetailsType(null);
	};

	const gridReturn = () => {
		return (
			<Grid container spacing={2}>
				<Grid item>
					<Box id="filter-panel" />
				</Grid>
				<Grid item style={{ height: 'auto', width: '100%' }}>
					<DataGrid
						rows={dataset || []}
						columns={columns(handleDetailsButton)} // Pass handlers here
						slots={{ toolbar: MyCustomToolbar }}
						autoHeight={true}
						loading={isLoading}
						initialState={{
							pagination: { paginationModel: { pageSize: 5 } },
						}}
						pageSizeOptions={[5, 10, 25]}
						slotProps={{
							loadingOverlay: {
								variant: 'linear-progress',
								noRowsVariant: 'skeleton',
							},
						}}
					/>
				</Grid>
				<Drawer
					anchor="right"
					open={openDrawer}
					onClose={handleCloseDrawer}
					PaperProps={{ sx: { width: '30%' } }}
				>
					<Box sx={{ padding: 2 }}>{RenderDrawerContent(drawerContent, detailsType)}</Box>
				</Drawer>
			</Grid>
		);
	};

	return (
		<>
			<Toaster />

			<h1 className="m-3">Freights</h1>
			<div className="m-10">{gridReturn()}</div>
		</>
	);
}
