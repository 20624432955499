import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { BookingRateProps } from '../../types/types'; // Adjust the path as needed

export const BookingRate: React.FC<BookingRateProps> = ({ percentageNumber = 0, loading, rateName }) => {
	// Determinar el color del gradiente basado en el valor de percentageNumber
	let gradientColor;
	if (percentageNumber >= 0 && percentageNumber <= 30) {
		gradientColor = 'linear-gradient(to top, rgba(255, 0, 0, 0.5), transparent)'; // Rojo
	} else if (percentageNumber > 30 && percentageNumber <= 50) {
		gradientColor = 'linear-gradient(to top, rgba(255, 165, 0, 0.5), transparent)'; // Naranja
	} else if (percentageNumber > 50 && percentageNumber <= 100) {
		gradientColor = 'linear-gradient(to top, rgba(0, 128, 0, 0.5), transparent)'; // Verde
	}
	const [showInfo, setShowInfo] = useState(false);

	const handleMouseEnter = () => {
		setShowInfo(true);
	};

	const handleMouseLeave = () => {
		setShowInfo(false);
	};

	return (
		<div
			className="rounded-md bg-white rounded-b  justify-between shadow-md"
			style={{ backgroundImage: gradientColor }}
		>
			<div className="pt-2 pl-4 h-10 bg-gray-800 flex justify-between rounded-t  align-middle">
				<p className="pr-2 text-white">
					{' '}
					<b> {rateName} Rate:</b>{' '}
				</p>
				<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="w-10">
					<FaInfoCircle color="white" size={20} className="mr-3" />
					{showInfo && (
						<div className="bg-white  shadow-2xl rounded-md p-2 w-72 border border-gray-300 absolute -mt-20 -ml-64">
							This card shows the percentage of bookings compared to the total amount of Freights
							published
						</div>
					)}
				</div>
			</div>
			<div className="flex flex-col justify-center leading-normal align-items items-center p-10">
				{loading ? 'Loading data...' : <p className="text-6xl"> {percentageNumber}%</p>}
			</div>
		</div>
	);
};
