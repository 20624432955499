// columns.ts

import { Button } from '@mui/material';
import { GridValueGetter } from '@mui/x-data-grid';

const idValueGetter: GridValueGetter = (value, row) => {
	const id = row.id;

	// Verificar si 'id' existe y no es null
	if (id !== undefined && id !== null) {
		return id;
	} else {
		return 'undefined';
	}
};

const itemValueGetter: GridValueGetter = (value, row) => {
	const itm = row.itm;

	// Verificar si 'status' existe y no es null
	if (itm !== undefined && itm !== null) {
		return itm;
	} else {
		return 'undefined';
	}
};

const amountValueGetter: GridValueGetter = (value, row) => {
	const amount = row.amount;
	// Verificar si 'status' existe y no es null
	if (amount !== undefined && amount !== null) {
		return [amount + '€'];
	} else {
		return 'undefined';
	}
};

const dateiEndValueGetter: GridValueGetter = (value, row) => {
	const date = row.pickup_date1;
	// Verificar si 'status' existe y no es null
	if (date !== undefined && date !== null) {
		return date;
	} else {
		return 'undefined';
	}
};
const dateEndValueGetter: GridValueGetter = (value, row) => {
	const date = row.dropoff_date1;
	// Verificar si 'status' existe y no es null
	if (date !== undefined && date !== null) {
		return date;
	} else {
		return 'undefined';
	}
};
const flagValueGetter: GridValueGetter = (value, row) => {
	const amountflag = row.amountflag;

	// Verificar si 'status' existe y no es null
	if (amountflag !== undefined && amountflag !== null) {
		return amountflag;
	} else {
		return 'No flag';
	}
};

// Definir las columnas personalizadas
const columns = (handleDetailsButton: any) => [
	{ field: 'id', headerName: 'ID', width: 40, valueGetter: idValueGetter },
	{
		field: 'Status',
		headerName: 'Status',
		flex: 1,
		renderCell: (params: any) => {
			// Define valid button colors based on state
			let buttonProps: { color: 'primary' | 'info' | 'success' | 'error' | 'warning'; label: string } = {
				color: 'warning', // Default button color
				label: 'Unknown',
			};

			switch (params.row.status) {
				case 'active':
					buttonProps = { color: 'warning', label: 'Active' };
					break;
				case 'accepted':
					buttonProps = { color: 'info', label: 'Accepted' };
					break;
				case 'cancelled':
					buttonProps = { color: 'error', label: 'Cancelled' };
					break;
				case 'declined':
					buttonProps = { color: 'error', label: 'Declined' };
					break;
				default:
					buttonProps = { color: 'warning', label: 'Unknown' };
					break;
			}

			return (
				<Button variant="contained" color={buttonProps.color} style={{ width: '100%', cursor: 'default' }}>
					{buttonProps.label}
				</Button>
			);
		},
	},
	{ field: 'item', flex: 1, headerName: 'Item', valueGetter: itemValueGetter },
	{ field: 'amount', headerName: 'Amount', width: 60, valueGetter: amountValueGetter },
	{
		field: 'Carrier name',
		headerName: 'Carrier Name',
		flex: 1,
		renderCell: (params: any) =>
			params?.row?.carrier ? (
				<Button variant="outlined" onClick={() => handleDetailsButton('carrier', params.row.carrier.id)}>
					{`${params?.row?.carrier?.firstname || 'N/A'} ${params?.row?.carrier?.lastname || ''}`}
				</Button>
			) : (
				'N/A'
			),
	},
	{
		field: 'Client name',
		headerName: 'Client Name',
		flex: 1,
		renderCell: (params: any) =>
			params?.row?.customer ? (
				<Button variant="outlined" onClick={() => handleDetailsButton('customer', params.row.customer.id)}>
					{`${params?.row?.customer?.firstname || 'N/A'} ${params?.row?.customer?.lastname || ''}`}
				</Button>
			) : (
				'N/A'
			),
	},
	{ field: 'datei', flex: 1, headerName: 'Pick up date', width: 170, valueGetter: dateiEndValueGetter },
	{ field: 'datee', flex: 1, headerName: 'Drop off date', width: 170, valueGetter: dateEndValueGetter },
	{ field: 'flag', flex: 1, headerName: 'Flag Amount', width: 200, valueGetter: flagValueGetter },
];

export default columns;
