import { Formik } from 'formik';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { BASE_URL } from '../api';
import { user_email } from '../atoms/atoms';
import background from '../img/Background.png';
import logo from '../img/logo.png';

const VerifyForgotOtp = () => {
	const [otp, setOtp] = useState('');
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [email, setEmail] = useAtom(user_email);
	const navigate = useNavigate();

	useEffect(() => {
		if (isFormSubmitted) {
			navigate('/update-password');
		}
	}, [isFormSubmitted]);

	const Schema = z.object({
		email: z.string().email(),
	});

	const userData = new FormData();
	userData.append('email', email);
	userData.append('otp', otp);

	const handleVerify = async () => {
		const data = await fetch(`${BASE_URL}forgotPassword/verify`, {
			method: 'POST',
			headers: {
				'Accept-Language': 'en-US,en;q=0.8',
			},
			body: userData,
		})
			.then((res) => res.json())
			.then((data) => {
				if (data.message === 'OTP verified successfully.') {
					toast.success('OTP verified successfully.');
					setIsFormSubmitted(true);
				} else {
					toast.error('Error validating OTP code.');
				}
			})
			.catch((err) => console.log(err));
		return data;
	};

	return (
		<>
			<Toaster />

			<div
				className="h-screen flex flex-col bg-gray-300 items-center justify-center bg-cover"
				style={{ backgroundImage: `url(${background})` }}
			>
				<div className="flex items-center">
					<div>
						<Formik<{ email: string }>
							validationSchema={toFormikValidationSchema(Schema)}
							initialValues={{
								email: '',
							}}
							onSubmit={(values, { setSubmitting }) => {
								setSubmitting(true);
								handleVerify();
								setSubmitting(false);
							}}
						>
							{({ handleChange, values, handleBlur, submitForm, handleSubmit, errors }) => (
								<form onSubmit={handleSubmit}>
									<div className="p-8 mx-auto flex flex-col bg-white justify-center border border-b-4 rounded-xl w-[540px] ">
										<img src={logo} width={200} alt="logo" className="mx-auto mb" />

										<div className="flex flex-col items-start mx-auto">
											<div className="flex flex-col">
												<label className="font-semibold mb-2">Código OTP</label>

												<div className="pb-2">
													<input
														type="number"
														name="otp"
														className="outline-none focus:ring-1 bg-white flex-1 rounded px-6 py-2 border border-gray-300 w-72"
														placeholder="Código OTP"
														onChange={(e) => setOtp(e.target.value)}
														value={otp}
													/>
												</div>
											</div>

											<button
												onClick={() => {
													handleVerify();
												}}
												style={{
													backgroundColor: '#ce3d5a',
												}}
												className="text-white shadow w-72 rounded p-2 font-semibold"
											>
												Enviar código
											</button>
										</div>
									</div>
								</form>
							)}
						</Formik>{' '}
					</div>
				</div>
			</div>
		</>
	);
};

export default VerifyForgotOtp;
