import FreightsTable from '../components/Freights/FreightsTable';
import HomeLayout from '../components/Layout/HomeLayout';

export const Freights = () => {
	return (
		<HomeLayout>
			<FreightsTable />
		</HomeLayout>
	);
};
