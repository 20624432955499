import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { BookingRate } from '../AdminPanel/BookingRate';
import { CarrierRoutes } from '../UserGraphs/CarrierRoutes';

export const RenderDrawerCarrierContent = (drawerContent: any, detailsType: any, user: any) => {
	if (!drawerContent)
		return (
			<>
				<div className="grid grid-cols-3 gap-4 p-4 rounded-lg shadow-md border border-gray-200 bg-white">
					<div className="col-span-2">
						<h2 className="text-lg font-bold text-gray-800 mb-3">
							<Skeleton className="h-6 w-40" />
						</h2>

						<div className="mb-4 grid grid-cols-2 ">
							<div className="col-span-1">
								<strong className="font-semibold">Name </strong>
								<p className="text-sm text-gray-600">
									<Skeleton className="h-5 w-60" />
								</p>
							</div>
							<div className="col-span-1">
								<strong className="font-semibold">Email </strong>
								<p className="text-sm text-gray-600">
									<Skeleton className="h-5 w-60" />
								</p>
							</div>
							<div className="col-span-1">
								<strong className="font-semibold">Phone </strong>
								<p className="text-sm text-gray-600">
									<Skeleton className="h-5 w-60" />
								</p>
							</div>
							<div>
								<strong className="font-semibold">Sign Up Date </strong>
								<p className="text-sm text-gray-600">
									<Skeleton className="h-5 w-40" />
								</p>
							</div>
						</div>
						<div>
							<CarrierRoutes
								freights={user?.user_stats.freights} // Pass null or placeholder data if needed
								instants={user?.user_stats.instant_freights}
								trips={user?.user_stats.trips}
								orders={user?.user_stats.orders}
								loading={true} // Indicate that loading is in progress
							/>
						</div>
						<div className="flex justify-between col-span-2 mt-4">
							<BookingRate percentageNumber={85} loading={true} rateName={'Completion'} />
							<BookingRate percentageNumber={65} loading={true} rateName={'Other'} />
						</div>
					</div>

					{/* Sidebar (1/3 of the grid) */}
					<div className="col-span-1 border-l border-gray-200 pl-4">
						<h3 className="text-md font-bold text-gray-800">
							<Skeleton className="h-6 w-32" />
						</h3>
						<p className="text-sm text-gray-600">
							<strong className="font-semibold">Total valoraciones: </strong>
							<Skeleton className="h-5 w-20" />
						</p>
						<Box sx={{ '& > legend': { mt: 2 } }}>
							<Typography component="legend">
								<Skeleton className="h-5 w-36" />
							</Typography>
							<Rating name="read-only" value={null} precision={1} readOnly />
							<Typography component="legend">
								<Skeleton className="h-5 w-36" />
							</Typography>
							<Rating name="read-only" value={null} precision={1} readOnly />
							<Typography component="legend">
								<Skeleton className="h-5 w-36" />
							</Typography>
							<Rating name="read-only" value={null} precision={1} readOnly />
							<Typography component="legend">
								<Skeleton className="h-5 w-36" />
							</Typography>
							<Rating name="read-only" value={null} precision={1} readOnly />
							<Typography component="legend">
								<Skeleton className="h-5 w-36" />
							</Typography>
							<Rating name="read-only" value={null} precision={1} readOnly />
						</Box>
					</div>
				</div>
			</>
		);

	return (
		<>
			<div className="grid grid-cols-3 gap-4 p-4 rounded-lg shadow-md border border-gray-200 bg-white">
				<div className="col-span-2">
					<h2 className="text-lg font-bold text-gray-800 mb-3">Carrier Details</h2>

					<div className="mb-4 grid grid-cols-2 ">
						<div className="col-span-1">
							<strong className="font-semibold">Name </strong>
							<p className="text-sm text-gray-600">
								{user?.firstname} {user?.lastname}
							</p>
						</div>
						<div className="col-span-1">
							<strong className="font-semibold">Email </strong>
							<p className="text-sm text-gray-600">{user?.email}</p>
						</div>
						<div className="col-span-1">
							<strong className="font-semibold">Phone </strong>
							<p className="text-sm text-gray-600">{user?.phoneNumber}</p>
						</div>
						<div>
							<strong className="font-semibold">Sign Up Date </strong>
							<p className="text-sm text-gray-600">{user?.signup_date?.split(' ')[0]}</p>
						</div>
					</div>
					<div>
						<CarrierRoutes
							freights={user.user_stats.freights}
							instants={user.user_stats.instant_freights}
							trips={user.user_stats.trips}
							orders={user.user_stats.orders}
							loading={false}
						/>
					</div>
					<div className="flex justify-between col-span-2 mt-4">
						<BookingRate percentageNumber={85} loading={false} rateName={'Completion'} />
						<BookingRate percentageNumber={65} loading={false} rateName={'Other'} />
					</div>
				</div>

				{/* Sidebar (1/3 of the grid) */}
				<div className="col-span-1 border-l border-gray-200 pl-4">
					<h3 className="text-md font-bold text-gray-800">Valoraciones</h3>
					<p className="text-sm text-gray-600">
						<strong className="font-semibold">Total valoraciones: </strong>
						{user?.reviews.total_reviews || '0'}
					</p>
					<Box sx={{ '& > legend': { mt: 2 } }}>
						<Typography component="legend">Average Service</Typography>
						<Rating name="read-only" value={user?.reviews.average_service} precision={1} readOnly />
						<Typography component="legend">Average Communication</Typography>
						<Rating name="read-only" value={user?.reviews.average_communication} precision={1} readOnly />
						<Typography component="legend">Average Care</Typography>
						<Rating name="read-only" value={user?.reviews.average_care} precision={1} readOnly />
						<Typography component="legend">Average Punctuality</Typography>
						<Rating name="read-only" value={user?.reviews.average_punctuality} precision={1} readOnly />
						<Typography component="legend">Average Customer Service</Typography>
						<Rating
							name="read-only"
							value={user?.reviews.average_customer_service}
							precision={1}
							readOnly
						/>
					</Box>
				</div>
			</div>
			<div className="grid grid-cols-3 mt-5 gap-4 p-4 rounded-lg shadow-md border border-gray-200 bg-white">
				{/* Main Content (2/3 of the grid) */}
				<div className="col-span-2">
					<h2 className="text-lg font-bold text-gray-800 mb-3">Other Carrier Details</h2>

					<div className="mb-4 grid grid-cols-2 ">
						<div className="col-span-1">
							<strong className="font-semibold">Name </strong>
							<p className="text-sm text-gray-600">
								{user?.firstname} {user?.lastname}
							</p>
						</div>
						<div className="col-span-1">
							<strong className="font-semibold">Email </strong>
							<p className="text-sm text-gray-600">{user?.email}</p>
						</div>
						<div className="col-span-1">
							<strong className="font-semibold">Phone </strong>
							<p className="text-sm text-gray-600">{user?.phoneNumber}</p>
						</div>
						<div>
							<strong className="font-semibold">Sign Up Date </strong>
							<p className="text-sm text-gray-600">{user?.signup_date?.split(' ')[0]}</p>
						</div>
					</div>
					<div>
						<CarrierRoutes
							freights={user.user_stats.freights}
							instants={user.user_stats.instant_freights}
							trips={user.user_stats.trips}
							orders={user.user_stats.orders}
							loading={false}
						/>
					</div>
					<div className="flex justify-between col-span-2 mt-4">
						<BookingRate percentageNumber={85} loading={false} rateName={'Completion'} />
						<BookingRate percentageNumber={65} loading={false} rateName={'Other'} />
					</div>
				</div>

				{/* Sidebar (1/3 of the grid) */}
				<div className="col-span-1 border-l border-gray-200 pl-4">
					<h3 className="text-md font-bold text-gray-800">Valoraciones</h3>
					<p className="text-sm text-gray-600">
						<strong className="font-semibold">Total valoraciones: </strong>
						{user?.reviews.total_reviews || '0'}
					</p>
					<Box sx={{ '& > legend': { mt: 2 } }}>
						<Typography component="legend">Average Service</Typography>
						<Rating name="read-only" value={user?.reviews.average_service} precision={1} readOnly />
						<Typography component="legend">Average Communication</Typography>
						<Rating name="read-only" value={user?.reviews.average_communication} precision={1} readOnly />
						<Typography component="legend">Average Care</Typography>
						1 <Rating name="read-only" value={user?.reviews.average_care} precision={1} readOnly />
						<Typography component="legend">Average Punctuality</Typography>
						<Rating name="read-only" value={user?.reviews.average_punctuality} precision={1} readOnly />
						<Typography component="legend">Average Customer Service</Typography>
						<Rating
							name="read-only"
							value={user?.reviews.average_customer_service}
							precision={1}
							readOnly
						/>
					</Box>
				</div>
			</div>
		</>
	);
};
