import { BarChart } from '@mui/x-charts/BarChart/BarChart';

import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { DailyQuotesProps } from 'src/types/types';

export const DailyQuotes: React.FC<DailyQuotesProps> = ({ loading }) => {
	const [showInfo, setShowInfo] = useState(false);

	const handleMouseEnter = () => {
		setShowInfo(true);
	};

	const handleMouseLeave = () => {
		setShowInfo(false);
	};
	return (
		<div>
			<div className="rounded-md bg-white rounded-b flex flex-col justify-between leading-normal shadow-md overflow-x-auto 2xl:overflow-hidden">
				<div className="pt-2 pl-4 h-10 bg-gray-800 flex rounded-t justify-between  align-middle">
					<p className="pr-2 text-white">
						<b> Daily Quotes:</b> 123467343{' '}
					</p>
					<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="w-10">
						<FaInfoCircle color="white" size={20} className="mr-3" />
						{showInfo && (
							<div className="bg-white  shadow-2xl rounded-md p-2 w-72 border border-gray-300 absolute -mt-20 -ml-64">
								This card shows how many Quotes are made per hour
							</div>
						)}
					</div>
				</div>
				<div style={{ minWidth: '600px' }}>
					{' '}
					{/* Asegura un ancho mínimo para el contenedor */}
					<BarChart
						loading={loading}
						xAxis={[
							{
								id: 'barCategories',
								data: [
									'01',
									'02',
									'03',
									'04',
									'05',
									'06',
									'07',
									'08',
									'00',
									'10',
									'11',
									'12',
									'13',
									'14',
									'15',
									'16',
									'17',
									'19',
									'20',
									'21',
									'22',
									'23',
									'24',
								],
								scaleType: 'band',
							},
						]}
						series={[
							{
								data: [
									600, 480, 850, 630, 220, 1050, 880, 1010, 360, 470, 340, 920, 890, 740, 1080, 1210,
									1670, 930, 600, 740,
								],
								color: '#649f26',
							},
						]}
						layout="vertical"
						width={720}
						height={150}
					/>
				</div>
			</div>
		</div>
	);
};
