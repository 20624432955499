import { Button } from '@mui/material';
import { GridValueGetter } from '@mui/x-data-grid';

const idValueGetter: GridValueGetter = (value, row) => row.id ?? 'undefined';
const freightValueGetter: GridValueGetter = (value, row) => row.title ?? 'undefined';
const dateValueGetter: GridValueGetter = (value, row) => row.created_at ?? 'undefined';
const dateEndValueGetter: GridValueGetter = (value, row) => row.end_date ?? 'undefined';
const originValueGetter: GridValueGetter = (value, row) =>
	[row.origin_address, row.origin_city, row.origin_state].filter(Boolean).join(', ') || 'undefined';
const destinationValueGetter: GridValueGetter = (value, row) =>
	[row.destination_address, row.destination_city, row.destination_state].filter(Boolean).join(', ') || 'undefined';
const distanceEndValueGetter: GridValueGetter = (value, row) => row.distance ?? 'undefined';
const quotesEndValueGetter: GridValueGetter = (value, row) => `${row.qcnt ?? 0} Active`;
const viewsEndValueGetter: GridValueGetter = (value, row) => `${row.views ?? 0} Views`;

// Define the columns for the DataGrid
const columns = (handleDetailsButton: any) => [
	{ field: 'id', headerName: 'ID', width: 40, flex: 1, valueGetter: idValueGetter },
	{
		field: 'Status',
		headerName: 'Status',
		flex: 1,
		renderCell: (params: any) => {
			// Define valid button colors based on state
			let buttonProps: { color: 'primary' | 'info' | 'success' | 'error' | 'warning'; label: string } = {
				color: 'warning', // Default button color
				label: 'Unknown',
			};

			switch (params.row.status) {
				case 'active':
					buttonProps = { color: 'warning', label: 'Active' };
					break;
				case 'booked':
					buttonProps = { color: 'info', label: 'Booked' };
					break;
				case 'complete':
					buttonProps = { color: 'success', label: 'Completed' };
					break;
				default:
					buttonProps = { color: 'warning', label: 'Unknown' };
					break;
			}

			return (
				<Button variant="contained" color={buttonProps.color} style={{ width: '100%', cursor: 'default' }}>
					{buttonProps.label}
				</Button>
			);
		},
	},
	{
		field: 'Client name',
		headerName: 'Client Name',
		flex: 1,
		renderCell: (params: any) =>
			params?.row?.customer ? (
				<Button variant="outlined" onClick={() => handleDetailsButton('customer', params.row.customer.id)}>
					{`${params?.row?.customer?.firstname || 'N/A'} ${params?.row?.customer?.lastname || ''}`}
				</Button>
			) : (
				'N/A'
			),
	},
	{
		field: 'Carrier name',
		headerName: 'Carrier Name',
		flex: 1,
		renderCell: (params: any) =>
			params?.row?.carrier ? (
				<Button variant="outlined" onClick={() => handleDetailsButton('carrier', params.row.carrier.id)}>
					{`${params?.row?.carrier?.firstname || 'N/A'} ${params?.row?.carrier?.lastname || ''}`}
				</Button>
			) : (
				'N/A'
			),
	},
	{ field: 'freight', headerName: 'Freight', width: 200, flex: 1, valueGetter: freightValueGetter },
	{ field: 'dates', headerName: 'Date Start', width: 170, flex: 1, valueGetter: dateValueGetter },
	{ field: 'datee', headerName: 'Date end', width: 170, flex: 1, valueGetter: dateEndValueGetter },
	{ field: 'origin', headerName: 'Origin', width: 200, flex: 1, valueGetter: originValueGetter },
	{
		field: 'destination',
		headerName: 'Destination',
		width: 200,
		flex: 1,
		valueGetter: destinationValueGetter,
	},

	{ field: 'distance', headerName: 'Distance', flex: 1, valueGetter: distanceEndValueGetter },
	{ field: 'quotes', headerName: 'Quotes', flex: 1, valueGetter: quotesEndValueGetter },
	{ field: 'views', headerName: 'Views', flex: 1, valueGetter: viewsEndValueGetter },
];

export default columns;
