import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

const useTokenStoreStorage = create(
	persist<{ token: string; addToken: (token: string) => void }>(
		(set) => ({
			token: '',
			addToken: (token: string) => set({ token }),
		}),
		{
			name: 'FreightseekToken',
			storage: createJSONStorage(() => localStorage),
		},
	),
);

export { useTokenStoreStorage };

const useUserIdStorage = create(
	persist<{ userId: string; setUserId: (userId: string) => void }>(
		(set, get) => ({
			userId: '',
			setUserId: (userId: string) => set({ userId }),
		}),
		{
			name: 'FreightseekId',
			storage: createJSONStorage(() => sessionStorage),
		},
	),
);
export { useUserIdStorage };
