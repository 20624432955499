import BookingsTable from '../components/Bookings/BookingsTable';
import HomeLayout from '../components/Layout/HomeLayout';

export const Bookings = () => {
	return (
		<HomeLayout>
			<BookingsTable />
		</HomeLayout>
	);
};
