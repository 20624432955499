import HomeLayout from '../../components/Layout/HomeLayout';
import { AdminTable } from '../../components/Users/AdminTable';

export const Admin = () => {
	return (
		<HomeLayout>
			<AdminTable />
		</HomeLayout>
	);
};
