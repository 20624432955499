import HomeLayout from '../../components/Layout/HomeLayout';
import { CustomerTable } from '../../components/Users/CustomerTable';

export const Customers = () => {
	return (
		<HomeLayout>
			<CustomerTable />
		</HomeLayout>
	);
};
