import React, { ReactNode } from 'react';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import { Bookings } from '../screens/Bookings';
import { Freights } from '../screens/Freights';
import { Home } from '../screens/Home';
import { InstantFreights } from '../screens/InstantFreights';
import Login from '../screens/Login';
import NotFound from '../screens/NotFound';
import PasswordForgot from '../screens/PasswordForgot';
import { Questions } from '../screens/Questions';
import { Quotes } from '../screens/Quotes';
import { Reviews } from '../screens/Reviews';
import { StoreDeliveries } from '../screens/StoreDeliveries';
import { Trips } from '../screens/Trips';
import UpdatePassword from '../screens/UpdatePassword';
import { Admin } from '../screens/Users/Admin';
import { Customers } from '../screens/Users/Customers';
import { Transporter } from '../screens/Users/Transporter';
import { Users } from '../screens/Users/Users';
import VerifyForgotOtp from '../screens/VerifyForgotOtp';
import ProtectedRoute from './ProtectedRoute';

interface LayoutProps {
	children?: ReactNode;
}
const Layout = ({ children }: LayoutProps) => <Outlet />;

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<Layout />}>
			<Route path="/login" element={<Login />} />
			<Route path="/forgot-password" element={<PasswordForgot />} />
			<Route path="/forgot-password/verify" element={<VerifyForgotOtp />} />
			<Route path="/update-password" element={<UpdatePassword />} />
			<Route path="*" element={<NotFound />} />
			<Route element={<ProtectedRoute />}>
				<Route path="/" element={<Home />} />
				<Route path="/freights" element={<Freights />} />
				<Route path="/InstantFreights" element={<InstantFreights />} />
				<Route path="/quotes" element={<Quotes />} />
				<Route path="/bookings" element={<Bookings />} />
				<Route path="/storeDeliveries" element={<StoreDeliveries />} />
				<Route path="/trips" element={<Trips />} />

				<Route path="/questions" element={<Questions />} />
				<Route path="/reviews" element={<Reviews />} />
				<Route path="/users" element={<Users />} />
				<Route path="/customers" element={<Customers />} />
				<Route path="/transporters" element={<Transporter />} />
				<Route path="/adminpannel" element={<Admin />} />
			</Route>
		</Route>,
	),
);

export default function Navigation() {
	return (
		<React.Suspense fallback={null}>
			<RouterProvider router={router} />
		</React.Suspense>
	);
}
