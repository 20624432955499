import HomeLayout from '../components/Layout/HomeLayout';
import QuotesTable from '../components/Quotes/QuotesTable';

export const Quotes = () => {
	return (
		<HomeLayout>
			<QuotesTable />
		</HomeLayout>
	);
};
