import { Skeleton } from '@mui/material';

export const RenderDrawerCustomerContent = (drawerContent: any, detailsType: any, user: any) => {
	if (!drawerContent)
		return (
			<>
				<div className="grid grid-cols-3 gap-4 p-4 rounded-lg shadow-md border border-gray-200 bg-white">
					<div className="col-span-2">
						<h2 className="text-lg font-bold text-gray-800 mb-3">Customer Details</h2>

						<div className="mb-4 grid grid-cols-2 ">
							<div className="col-span-1">
								<strong className="font-semibold">Name </strong>
								<p className="text-sm text-gray-600">
									<Skeleton className="h-5 w-60" />
								</p>
							</div>
							<div className="col-span-1">
								<strong className="font-semibold">Email </strong>
								<p className="text-sm text-gray-600">
									<Skeleton className="h-5 w-60" />
								</p>
							</div>
							<div className="col-span-1">
								<strong className="font-semibold">Phone </strong>
								<p className="text-sm text-gray-600">
									<Skeleton className="h-5 w-60" />
								</p>
							</div>
							<div>
								<strong className="font-semibold">Sign Up Date </strong>
								<p className="text-sm text-gray-600">
									<Skeleton className="h-5 w-40" />
								</p>
							</div>
						</div>
					</div>
				</div>
			</>
		);

	return (
		<>
			<div className="grid grid-cols-3 gap-4 p-4 rounded-lg shadow-md border border-gray-200 bg-white">
				<div className="col-span-2">
					<h2 className="text-lg font-bold text-gray-800 mb-3">Customer Details</h2>

					<div className="mb-4 grid grid-cols-2 ">
						<div className="col-span-1">
							<strong className="font-semibold">Name </strong>
							<p className="text-sm text-gray-600">
								{user?.firstname} {user?.lastname}
							</p>
						</div>
						<div className="col-span-1">
							<strong className="font-semibold">Email </strong>
							<p className="text-sm text-gray-600">{user?.email}</p>
						</div>
						<div className="col-span-1">
							<strong className="font-semibold">Phone </strong>
							<p className="text-sm text-gray-600">{user?.phoneNumber}</p>
						</div>
						<div>
							<strong className="font-semibold">Sign Up Date </strong>
							<p className="text-sm text-gray-600">{user?.signup_date?.split(' ')[0]}</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
