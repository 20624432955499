import { Portal } from '@mui/base/Portal';
import { Drawer } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DataGrid, GridToolbar, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useTokenStoreStorage, useUserIdStorage } from 'src/hooks/useTokenStore';
import { BASE_URL } from '../../api';
import { RenderDrawerContent } from '../Layout/Drawer';
import columns from './TripsTable.columns';

function MyCustomToolbar(props: any) {
	return (
		<React.Fragment>
			<Portal container={() => document.getElementById('filter-panel')!}>
				<GridToolbarQuickFilter />
			</Portal>
			<GridToolbar {...props} />
		</React.Fragment>
	);
}

export default function TripsTable() {
	const [openDrawer, setOpenDrawer] = useState(false); // Single drawer state
	const [drawerContent, setDrawerContent] = useState<any>(null); // Content to show in drawer
	const [detailsType, setDetailsType] = useState<'carrier' | 'customer' | null>(null); // Track if it's carrier or customer details
	const token = useTokenStoreStorage((s) => s.token);
	const userId = useUserIdStorage((s) => s.userId);

	const fetchOrders = async () => {
		const response = await axios.get(`${BASE_URL}admin/all-routes`, {
			headers: {
				AccessToken: token,
				'Content-Type': 'application/json',
			},
			params: {
				user_id: userId,
			},
		});

		return response.data.data.routes;
	};

	const {
		data: dataset,
		isLoading,
		error,
		refetch,
	} = useQuery({
		queryKey: ['orders'],
		queryFn: fetchOrders,
	});

	const handleCloseDrawer = () => {
		setOpenDrawer(false);
		setDrawerContent(null);
		setDetailsType(null);
	};

	const gridReturn = () => (
		<Grid container spacing={2}>
			<Grid item>
				<Box id="filter-panel" />
			</Grid>
			<Grid item style={{ height: 'auto', width: '100%' }}>
				<DataGrid
					rows={dataset || []}
					columns={columns(handleDetailsButton)}
					slots={{ toolbar: MyCustomToolbar }}
					autoHeight={true}
					loading={isLoading}
					initialState={{
						pagination: { paginationModel: { pageSize: 5 } },
					}}
					pageSizeOptions={[5, 10, 25]}
					slotProps={{
						loadingOverlay: {
							variant: 'linear-progress',
							noRowsVariant: 'skeleton',
						},
					}}
				/>
			</Grid>
			<Drawer anchor="right" open={openDrawer} onClose={handleCloseDrawer} PaperProps={{ sx: { width: '50%' } }}>
				<Box sx={{ padding: 2 }}>{RenderDrawerContent(drawerContent, detailsType)}</Box>
			</Drawer>
		</Grid>
	);

	const handleDetailsButton = async (type: 'carrier' | 'customer', id: string) => {
		setDetailsType(type);
		setOpenDrawer(true);

		try {
			console.log('dt:', detailsType);

			const endpoint = type === 'carrier' ? 'carrier-details' : 'customer-details';
			const response = await axios.get(`${BASE_URL}admin/${endpoint}`, {
				headers: {
					AccessToken: token,
					'Content-Type': 'application/json',
				},
				params: {
					user_id: userId,
					[`${type}_id`]: id,
				},
			});
			setDrawerContent(response.data.data); // Store details in the drawer content
		} catch (error) {
			toast.error(`Error fetching ${type} details`);
		}
	};

	return (
		<>
			<Toaster />
			<div className="m-10">{gridReturn()}</div>
		</>
	);
}
