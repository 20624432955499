import { Portal } from '@mui/base/Portal';
import { Unstable_NumberInput as BaseNumberInput, NumberInputProps } from '@mui/base/Unstable_NumberInput';
import { Button, Drawer, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { blue } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import { GridRowParams, GridToolbar, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { VscLoading } from 'react-icons/vsc';
import { useTokenStoreStorage, useUserIdStorage } from 'src/hooks/useTokenStore';
import { BASE_URL } from '../../api';
import { RenderDrawerContent } from '../Layout/Drawer';
import columns from './StoreDeliveriesTable.columns';
import { StyledButton, StyledDataGrid, StyledInputElement, StyledInputRoot } from './StoreDeliveriesTable.theme';

function MyCustomToolbar(props: any) {
	return (
		<React.Fragment>
			<Portal container={() => document.getElementById('filter-panel')!}>
				<GridToolbarQuickFilter />
			</Portal>
			<GridToolbar {...props} />
		</React.Fragment>
	);
}

const NumberInput = React.forwardRef(function CustomNumberInput(
	props: NumberInputProps,
	ref: React.ForwardedRef<HTMLDivElement>,
) {
	return (
		<BaseNumberInput
			slots={{
				root: StyledInputRoot,
				input: StyledInputElement,
				incrementButton: StyledButton,
				decrementButton: StyledButton,
			}}
			slotProps={{
				incrementButton: {
					children: '▴',
				},
				decrementButton: {
					children: '▾',
				},
			}}
			{...props}
			ref={ref}
		/>
	);
});

export default function StoreDeliveriesTable() {
	const [openModal, setOpenModal] = React.useState(false);
	const [selectedRow, setSelectedRow] = React.useState<any>(null);
	const [daysUntilExpiration, setDaysUntilExpiration] = React.useState<any>(null);
	const [deleting, setDeleting] = React.useState(false);
	const [updating, setUpdating] = React.useState(false);
	const [openDrawer, setOpenDrawer] = useState(false); // Single drawer state
	const [drawerContent, setDrawerContent] = useState<any>(null); // Content to show in drawer
	const [detailsType, setDetailsType] = useState<'carrier' | 'customer' | null>(null); // Track if it's carrier or customer details

	const token = useTokenStoreStorage((s) => s.token);
	const userId = useUserIdStorage((s) => s.userId);

	const fetchOrders = async () => {
		const response = await axios.get(`${BASE_URL}admin/list-orders-admin`, {
			headers: {
				AccessToken: token,
				'Content-Type': 'application/json',
			},
			params: {
				user_id: userId,
			},
		});

		return response.data.data.orders;
	};

	const {
		data: dataset,
		isLoading,
		error,
		refetch,
	} = useQuery({
		queryKey: ['orders'],
		queryFn: fetchOrders,
	});

	const handleDelete = async () => {
		setDeleting(true); // Set deleting to true when starting delete
		try {
			await axios.delete(`${BASE_URL}orders/delete-order`, {
				headers: {
					AccessToken: token,
				},
				params: {
					user_id: userId,
					order_id: selectedRow.id,
				},
			});
			console.log('Data deleted successfully.');
			refetch();
			setOpenModal(false); // Close modal after successful delete
			toast.success('Order deleted succesfully!');
		} catch (error) {
			console.error('Error deleting data:', error);
		} finally {
			setDeleting(false); // Set deleting to false after delete operation completes
		}
	};
	const handleUpdateExpirationDays = async () => {
		setUpdating(true);

		try {
			await axios.post(
				`${BASE_URL}admin/update-expiration-days`,
				{},
				{
					headers: {
						AccessToken: token,
					},
					params: {
						user_id: userId,
						value: daysUntilExpiration,
					},
				},
			);
			console.log('Data deleted successfully.');
			toast.success('Days of expiration updated correctly!');
		} catch (error: unknown) {
			if (axios.isAxiosError(error)) {
				// Access the error response if it's an Axios error
				console.error('Error updating data:', error);
				// Use the error message from the response if available
				const message = error.response?.data?.message || 'An error occurred while updating.';
				toast.error(message);
			} else {
				// Handle non-Axios errors
				console.error('Unexpected error:', error);
				toast.error('An unexpected error occurred');
			}
		} finally {
			setUpdating(false);
		}
	};

	const handleOpenModal = (row: any) => {
		setSelectedRow(row);
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const handleDetailsButton = async (type: 'carrier' | 'customer', id: string) => {
		setDetailsType(type);
		setOpenDrawer(true);

		try {
			const endpoint = type === 'carrier' ? 'carrier-details' : 'customer-details';
			const response = await axios.get(`${BASE_URL}admin/${endpoint}`, {
				headers: {
					AccessToken: token,
					'Content-Type': 'application/json',
				},
				params: {
					user_id: userId,
					[`${type}_id`]: id,
				},
			});
			setDrawerContent(response.data.data); // Store details in the drawer content
		} catch (error) {
			toast.error(`Error fetching ${type} details`);
		}
	};

	const handleCloseDrawer = () => {
		setOpenDrawer(false);
		setDrawerContent(null);
		setDetailsType(null);
	};

	const gridReturn = () => (
		<Grid container spacing={2}>
			<Grid item>
				<Box id="filter-panel" />
			</Grid>
			<Grid item style={{ height: 'auto', width: '100%' }}>
				<StyledDataGrid
					rows={dataset || []}
					columns={columns(handleDetailsButton, handleOpenModal)} // Pass handlers here
					slots={{ toolbar: MyCustomToolbar }}
					autoHeight
					loading={isLoading}
					initialState={{
						pagination: { paginationModel: { pageSize: 5 } },
					}}
					pageSizeOptions={[5, 10, 25]}
					slotProps={{
						loadingOverlay: {
							variant: 'linear-progress',
							noRowsVariant: 'skeleton',
						},
					}}
					getRowClassName={(params: GridRowParams) => `super-app-theme--${params.row.state}`}
				/>
			</Grid>
			<Modal open={openModal} onClose={handleCloseModal}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
					}}
				>
					<p className="mb-6 text-center">Are you sure you want to ARCHIVE?</p>
					<div className="flex justify-between">
						<Button onClick={handleDelete} color="error" variant="contained" sx={{ marginRight: 3 }}>
							{deleting ? <VscLoading className="animate-spin" /> : 'ARCHIVE'}
						</Button>
						<Button onClick={handleCloseModal} color="primary" variant="contained">
							Cancel
						</Button>
					</div>
				</Box>
			</Modal>
			<Drawer anchor="right" open={openDrawer} onClose={handleCloseDrawer} PaperProps={{ sx: { width: '50%' } }}>
				<Box sx={{ padding: 2 }}>{RenderDrawerContent(drawerContent, detailsType)}</Box>
			</Drawer>
		</Grid>
	);

	return (
		<>
			<Toaster />
			<h1 className="m-10">Store Deliveries</h1>
			<div className="m-10 flex-column text-end">
				<h3>Days after creation date without assignment to expire orders</h3>
				<div className="flex gap-5 justify-end mt-2 ">
					<NumberInput
						aria-label="Demo number input"
						placeholder="Select days"
						min={1}
						max={30}
						value={daysUntilExpiration}
						onChange={(event, val) => setDaysUntilExpiration(val)}
					/>
					<Button
						style={{
							backgroundColor: blue[500],
							color: '#fff',
							width: '10%',
						}}
						onClick={handleUpdateExpirationDays}
					>
						Update
					</Button>
				</div>
			</div>
			<div className="m-10">{gridReturn()}</div>
			{/* {updating && <LoadingOverlay />} */}
		</>
	);
}
