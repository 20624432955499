import { Box, Container, Grid, Typography } from '@mui/material';
import logo from '../../img/NE.png';
export const Footer = () => {
	return (
		<div className="bg-slate-800">
			<Box
				sx={{
					width: '100%',
					height: 'auto',
					paddingTop: '1rem',
					paddingBottom: '1rem',
					color: 'white',
				}}
			>
				<Container maxWidth="lg">
					<Grid container direction="column" alignItems="center">
						<Grid item xs={12}>
							<img src={logo} className="w-32" alt="" />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="subtitle1">
								{`${new Date().getFullYear()} | React | Typescript | React Router`}
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</div>
	);
};
