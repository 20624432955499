import { ReactNode } from 'react';
import { Footer } from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

interface HomeLayoutProps {
	children?: ReactNode;
}
const HomeLayout: React.FC<HomeLayoutProps> = ({ children }) => {
	return (
		<div>
			<div>
				<Navbar />
			</div>
			<div className="min-h-[100vh]">{children}</div>
			<div>
				<Footer />
			</div>
		</div>
	);
};

export default HomeLayout;
