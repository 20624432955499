import React from 'react';
import { Footer } from '../components/Footer/Footer';
import gif from '../img/New Lottie.gif';
type Props = {};

const NotFound: React.FC<Props> = () => {
	return (
		<div
			className="h-screen flex flex-col bg-gray-300 items-center justify-center bg-cover"
			style={{ backgroundColor: '#F1F3F7' }}
		>
			<div className="h-[100vh] flex items-center">
				<img src={gif} className="w-[800px] h-[600px]" />
			</div>
			<div className="w-[100%]">
				<Footer />
			</div>
		</div>
	);
};

export default NotFound;
