import { Box, Button, Grid, Modal, Portal } from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarQuickFilter, GridValueGetter } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import { BASE_URL } from '../../api';
import { useTokenStoreStorage, useUserIdStorage } from '../../hooks/useTokenStore';

function MyCustomToolbar(props: any) {
	return (
		<React.Fragment>
			<Portal container={() => document.getElementById('filter-panel')!}>
				<GridToolbarQuickFilter />
			</Portal>
			<GridToolbar {...props} />
		</React.Fragment>
	);
}

export function ReviewsTable() {
	const [data, setData] = React.useState([]); // Estado para almacenar los datos del servidor
	const [openModal, setOpenModal] = React.useState(false);
	const [selectedRow, setSelectedRow] = React.useState<any>(null); // Especifica el tipo de selectedRow
	const token = useTokenStoreStorage((s) => s.token);
	const userId = useUserIdStorage((s) => s.userId);
	const handleOpenModal = (row: any) => {
		setSelectedRow(row);
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const fetchData = async () => {
		try {
			const response = await axios.get(`${BASE_URL}admin/reviewList`, {
				headers: {
					AccessToken: token,
					'Content-Type': 'application/json',
				},
				params: {
					user_id: userId,
				},
			});

			return response.data.data.datas.data;
		} catch (error) {
			console.error('Error al obtener los datos:', error);
		}
	};

	const {
		data: dataset,
		isLoading,
		error,
	} = useQuery({
		queryKey: ['reviews'],
		queryFn: fetchData,
	});

	const handleDelete = async () => {
		try {
			await axios.delete(`${BASE_URL}freight/delete`, {
				headers: {
					AccessToken: token,
				},
				params: {
					user_id: userId,
					freight_id: selectedRow.id,
				},
			});
			console.log('Data deleted successfully.');
			fetchData();
			setOpenModal(false); // Cerrar modal después de la eliminación exitosa
		} catch (error) {
			console.error('Error deleting data:', error);
		}
	};

	const idValueGetter: GridValueGetter = (value, row) => {
		const id = row.id;

		// Verificar si 'id' existe y no es null
		if (id !== undefined && id !== null) {
			return id;
		} else {
			return 'undefined';
		}
	};

	// Función para obtener el valor de la columna 'status'
	const bookingIdValueGetter: GridValueGetter = (value, row) => {
		const bookingid = row.bookingid;

		// Verificar si 'status' existe y no es null
		if (bookingid !== undefined && bookingid !== null) {
			return bookingid;
		} else {
			return 'undefined';
		}
	};

	const customeremailValueGetter: GridValueGetter = (value, row) => {
		const customeremail = row.customeremail;

		// Verificar si 'status' existe y no es null
		if (customeremail !== undefined && customeremail !== null) {
			return customeremail;
		} else {
			return 'undefined';
		}
	};

	const experienceValueGetter: GridValueGetter = (value, row) => {
		const experience = row.experience;
		// Verificar si 'status' existe y no es null
		if (experience !== undefined && experience !== null) {
			return experience;
		} else {
			return 'undefined';
		}
	};

	const rating_avgValueGetter: GridValueGetter = (value, row) => {
		const rating_avg = row.rating_avg;
		// Verificar si 'status' existe y no es null
		if (rating_avg !== undefined && rating_avg !== null) {
			return rating_avg + '/5';
		} else {
			return 'undefined';
		}
	};

	const rating_careValueGetter: GridValueGetter = (value, row) => {
		const rating_care = row.rating_care;
		// Verificar si 'status' existe y no es null
		if (rating_care !== undefined && rating_care !== null) {
			return rating_care + '/5';
		} else {
			return 'undefined';
		}
	};
	const rating_communicationValueGetter: GridValueGetter = (value, row) => {
		const rating_communication = row.rating_communication;
		// Verificar si 'status' existe y no es null
		if (rating_communication !== undefined && rating_communication !== null) {
			return rating_communication + '/5';
		} else {
			return 'undefined';
		}
	};

	const rating_customer_serviceValueGetter: GridValueGetter = (value, row) => {
		const rating_customer_service = row.rating_customer_service;
		// Verificar si 'status' existe y no es null
		if (rating_customer_service !== undefined && rating_customer_service !== null) {
			return rating_customer_service + '/5';
		} else {
			return 'undefined';
		}
	};

	const rating_punctualityValueGetter: GridValueGetter = (value, row) => {
		const rating_punctuality = row.rating_punctuality;
		// Verificar si 'status' existe y no es null
		if (rating_punctuality !== undefined && rating_punctuality !== null) {
			return rating_punctuality + '/5';
		} else {
			return 'undefined';
		}
	};
	const rating_serviceValueGetter: GridValueGetter = (value, row) => {
		const rating_service = row.rating_service;
		// Verificar si 'status' existe y no es null
		if (rating_service !== undefined && rating_service !== null) {
			return rating_service + '/5';
		} else {
			return 'undefined';
		}
	};
	const transporterValueGetter: GridValueGetter = (value, row) => {
		const transporter = row.transporter;
		// Verificar si 'status' existe y no es null
		if (transporter !== undefined && transporter !== null) {
			return transporter;
		} else {
			return 'undefined';
		}
	};
	const titleValueGetter: GridValueGetter = (value, row) => {
		const title = row.title;
		// Verificar si 'status' existe y no es null
		if (title !== undefined && title !== null) {
			return title;
		} else {
			return 'undefined';
		}
	};
	const reviewValueGetter: GridValueGetter = (value, row) => {
		const review = row.review;
		// Verificar si 'status' existe y no es null
		if (review !== undefined && review !== null) {
			return review;
		} else {
			return 'undefined';
		}
	};

	// Definir las columnas personalizadas
	const columns = React.useMemo(
		() => [
			{ field: 'id', flex: 1, headerName: 'ID', width: 70, valueGetter: idValueGetter },
			{ field: 'Booking Id', flex: 1, headerName: 'Booking ID', valueGetter: bookingIdValueGetter },
			{ field: 'Customer', flex: 1, headerName: 'Customer', width: 200, valueGetter: customeremailValueGetter },
			{ field: 'Experience', flex: 1, headerName: 'Experience', valueGetter: experienceValueGetter },
			{
				field: 'Average Rating',
				flex: 1,
				headerName: 'Average Rating',
				width: 130,
				valueGetter: rating_avgValueGetter,
			},
			{ field: 'Care Rating', flex: 1, headerName: 'Care Rating', valueGetter: rating_careValueGetter },
			{
				field: 'Communication Rating',
				flex: 1,
				headerName: 'Communication Rating',
				width: 170,
				valueGetter: rating_communicationValueGetter,
			},
			{
				field: 'Service Rating',
				flex: 1,
				headerName: 'Service Rating',
				valueGetter: rating_customer_serviceValueGetter,
			},
			{
				field: 'Punctuality Rating',
				flex: 1,
				headerName: 'Punctuality Rating',
				valueGetter: rating_punctualityValueGetter,
			},
			{
				field: 'Service Rating',
				flex: 1,
				headerName: 'Service Rating',
				valueGetter: rating_serviceValueGetter,
			},
			{ field: 'Transporter ', flex: 1, headerName: ' Transporter', valueGetter: transporterValueGetter },
			{ field: 'Item ', flex: 1, headerName: ' Item', width: 200, valueGetter: titleValueGetter },
			{ field: 'Message ', flex: 1, headerName: ' Message', width: 200, valueGetter: reviewValueGetter },

			{
				field: 'action',
				flex: 1,
				headerName: 'Action',
				renderCell: (params: any) => (
					<Button variant="contained" color="error" onClick={() => handleOpenModal(params.row)}>
						Delete
					</Button>
				),
			},
		],
		[],
	);

	const gridReturn = () => (
		<Grid container spacing={2}>
			<Grid item>
				<Box id="filter-panel" />
			</Grid>
			<Grid item style={{ height: 'auto', width: '100%' }}>
				<DataGrid
					rows={dataset || []}
					columns={columns}
					slots={{ toolbar: MyCustomToolbar }}
					autoHeight={true}
					loading={isLoading}
					initialState={{
						pagination: { paginationModel: { pageSize: 5 } },
					}}
					pageSizeOptions={[5, 10, 25]}
					slotProps={{
						loadingOverlay: {
							variant: 'linear-progress',
							noRowsVariant: 'skeleton',
						},
					}}
				/>
			</Grid>
			<Modal open={openModal} onClose={handleCloseModal}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
					}}
				>
					<p className="mb-6 text-center"> Are You sure to DELETE?</p>
					<div className="flex justify-between">
						<Button onClick={handleDelete} color="error" variant="contained" sx={{ marginRight: 3 }}>
							Delete
						</Button>
						<Button onClick={handleCloseModal} color="primary" variant="contained">
							Cancel
						</Button>
					</div>
				</Box>
			</Modal>
		</Grid>
	);
	return (
		<>
			<h1 className="m-3">Reviews</h1>
			<div className="m-10">{gridReturn()}</div>
		</>
	);
}
