import HomeLayout from '../components/Layout/HomeLayout';
import { QuestionsTable } from '../components/Questions/QuestionsTable';

export const Questions = () => {
	return (
		<HomeLayout>
			<QuestionsTable />
		</HomeLayout>
	);
};
