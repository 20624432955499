import { Button } from '@mui/material';
import { GridValueGetter } from '@mui/x-data-grid';

const idValueGetter: GridValueGetter = (value, row) => {
	const id = row.id;
	return id !== undefined && id !== null ? id : 'undefined';
};

const originValueGetter: GridValueGetter = (value, row) => {
	return row.origin_city || 'undefined';
};

const destinationValueGetter: GridValueGetter = (value, row) => {
	return row.destination_city || 'undefined';
};

const runnmePriceValueGetter: GridValueGetter = (value, row) => {
	return Number(row.price) || 'undefined';
};

const createDateValueGetter: GridValueGetter = (value, row) => {
	return row.created_at || 'undefined';
};

const startDateValueGetter: GridValueGetter = (value, row) => {
	return row.start_date || 'undefined';
};
const endDateValueGetter: GridValueGetter = (value, row) => {
	return row.end_date || 'undefined';
};

const columns = (handleDetailsButton: any) => [
	{ field: 'id', headerName: 'ID', width: 20, valueGetter: idValueGetter },
	{
		field: 'Status',
		headerName: 'Status',
		flex: 1,
		renderCell: (params: any) => {
			// Define valid button colors based on state
			let buttonProps: { color: 'primary' | 'info' | 'success' | 'error' | 'warning'; label: string } = {
				color: 'warning', // Default button color
				label: 'Unknown',
			};

			switch (params.row.status) {
				case 'created':
					buttonProps = { color: 'warning', label: 'Created' };
					break;
				case 'confirmed':
					buttonProps = { color: 'info', label: 'Confirmed' };
					break;
				case 'cancelled':
					buttonProps = { color: 'error', label: 'Cancelled' };
					break;
				default:
					buttonProps = { color: 'warning', label: 'Unknown' };
					break;
			}

			return (
				<Button variant="contained" color={buttonProps.color} style={{ width: '100%', cursor: 'default' }}>
					{buttonProps.label}
				</Button>
			);
		},
	},
	{
		field: 'Carrier name',
		headerName: 'Carrier Name',
		flex: 1,
		renderCell: (params: any) =>
			params?.row?.carrier ? (
				<Button variant="outlined" onClick={() => handleDetailsButton('carrier', params.row.carrier.id)}>
					{`${params?.row?.carrier?.firstname || 'N/A'} ${params?.row?.carrier?.lastname || ''}`}
				</Button>
			) : (
				'N/A'
			),
	},
	{ field: 'Origin', headerName: 'Origin', flex: 1, valueGetter: originValueGetter },
	{ field: 'Destination', headerName: 'Destination', flex: 1, valueGetter: destinationValueGetter },
	{ field: 'Price', headerName: 'Price', flex: 1, valueGetter: runnmePriceValueGetter },
	{ field: 'Create date', headerName: 'Create Date', flex: 1, valueGetter: createDateValueGetter },
	{ field: 'Start date', headerName: 'Start Date', flex: 1, valueGetter: startDateValueGetter },
	{ field: 'End date', headerName: 'End Date', flex: 1, valueGetter: endDateValueGetter },
];

export default columns;
