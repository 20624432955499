// columns.ts

import { Button } from '@mui/material';
import { GridValueGetter } from '@mui/x-data-grid';

const idValueGetter: GridValueGetter = (value, row) => {
	const id = row.id;

	// Verificar si 'id' existe y no es null
	if (id !== undefined && id !== null) {
		return id;
	} else {
		return 'undefined';
	}
};

const quote_amountValueGetter: GridValueGetter = (value, row) => {
	const quote_amount = row.quote_amount;
	// Check if 'quote_amount' exists and is not null
	if (quote_amount !== undefined && quote_amount !== null) {
		// Format the number with comma as thousand separator and two decimal places
		return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
			quote_amount,
		);
	} else {
		return 'undefined';
	}
};

const booking_feeValueGetter: GridValueGetter = (value, row) => {
	const booking_fee = row.booking_fee;
	// Verificar si 'status' existe y no es null
	if (booking_fee !== undefined && booking_fee !== null) {
		return booking_fee;
	} else {
		return 'undefined';
	}
};

const uemailValueGetter: GridValueGetter = (value, row) => {
	const uemail = row.uemail;
	// Verificar si 'status' existe y no es null
	if (uemail !== undefined && uemail !== null) {
		return uemail;
	} else {
		return 'undefined';
	}
};
const carrValueGetter: GridValueGetter = (value, row) => {
	const carr = row.carr;
	// Verificar si 'status' existe y no es null
	if (carr !== undefined && carr !== null) {
		return carr;
	} else {
		return 'undefined';
	}
};

const date_bookedValueGetter: GridValueGetter = (value, row) => {
	const date_booked = row.date_booked;
	// Verificar si 'status' existe y no es null
	if (date_booked !== undefined && date_booked !== null) {
		return date_booked;
	} else {
		return 'undefined';
	}
};

const paymentValueGetter: GridValueGetter = (value, row) => {
	const payment = row.is_paid;
	// Verificar si 'status' existe y no es null
	if (payment !== undefined && payment !== null) {
		return [payment];
	} else {
		return 'undefined';
	}
};

const userAgentValueGetter: GridValueGetter = (value, row) => {
	const device = row.device;
	const platform = row.platform;
	const browser = row.browser;
	// Verificar si 'status' existe y no es null
	if (device !== undefined && device !== null) {
		return [device + ' ' + platform + '' + browser];
	} else {
		return 'undefined';
	}
};

// Definir las columnas personalizadas
const columns = (handleDetailsButton: any) => [
	{ field: 'id', flex: 1, headerName: 'ID', width: 70, valueGetter: idValueGetter },
	{
		field: 'Status',
		headerName: 'Status',
		flex: 1,
		renderCell: (params: any) => {
			// Define valid button colors based on state
			let buttonProps: { color: 'primary' | 'info' | 'success' | 'error' | 'warning'; label: string } = {
				color: 'warning', // Default button color
				label: 'Unknown',
			};

			switch (params.row.status) {
				case 'created':
					buttonProps = { color: 'warning', label: 'Created' };
					break;
				case 'booked':
					buttonProps = { color: 'info', label: 'Booked' };
					break;
				case 'complete':
					buttonProps = { color: 'success', label: 'Completed' };
					break;
				case 'cancelled':
					buttonProps = { color: 'error', label: 'Cancelled' };
					break;
				default:
					buttonProps = { color: 'warning', label: 'Unknown' };
					break;
			}

			return (
				<Button variant="contained" color={buttonProps.color} style={{ width: '100%', cursor: 'default' }}>
					{buttonProps.label}
				</Button>
			);
		},
	},
	{ field: 'Is paid', flex: 1, headerName: 'Payment', valueGetter: paymentValueGetter },
	{ field: 'Amount', flex: 1, headerName: 'Amount', valueGetter: quote_amountValueGetter },
	{ field: 'Booking Fee', flex: 1, headerName: 'Booking Fee', valueGetter: booking_feeValueGetter },
	{
		field: 'Client name',
		headerName: 'Client Name',
		flex: 1,
		renderCell: (params: any) =>
			params?.row?.customer ? (
				<Button variant="outlined" onClick={() => handleDetailsButton('customer', params.row.customer.id)}>
					{`${params?.row?.customer?.firstname || 'N/A'} ${params?.row?.customer?.lastname || ''}`}
				</Button>
			) : (
				'N/A'
			),
	},
	{
		field: 'Carrier name',
		headerName: 'Carrier Name',
		flex: 1,
		renderCell: (params: any) =>
			params?.row?.carrier ? (
				<Button variant="outlined" onClick={() => handleDetailsButton('carrier', params.row.carrier.id)}>
					{`${params?.row?.carrier?.firstname || 'N/A'} ${params?.row?.carrier?.lastname || ''}`}
				</Button>
			) : (
				'N/A'
			),
	},
	{
		field: 'Date Booked',
		flex: 1,
		headerName: 'Date Booked',
		width: 200,
		valueGetter: date_bookedValueGetter,
	},
	{ field: 'views', flex: 1, headerName: 'Views', width: 200, valueGetter: userAgentValueGetter },
];

export default columns;
