import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTokenStoreStorage, useUserIdStorage } from 'src/hooks/useTokenStore';
import { BASE_URL } from '../api';
import { BookingRate } from '../components/AdminPanel/BookingRate';
import { DailyQuotes } from '../components/AdminPanel/DailyQuotes';
import { EarningGraph } from '../components/AdminPanel/EarningGraph';
import { MonthlySummary } from '../components/AdminPanel/MonthlySummary';
import { QuoteVsBookings } from '../components/AdminPanel/QuoteVsBookings';
import { UsersCard } from '../components/AdminPanel/UsersCard';
import HomeLayout from '../components/Layout/HomeLayout';

export const Home = () => {
	const [allTransporters, setAllTransporters] = useState(0);
	const [allCustomers, setAllCustomers] = useState(0);
	const [bookingNumber, setBookingNumber] = useState(0);
	const [completedNumber, setCompletedNumber] = useState(0);
	const [cancelledNumber, setCancelledNumber] = useState(0);
	const [freights, setFreights] = useState(0);
	const [bookings, setBookings] = useState(0);
	const [completed, setCompleted] = useState(0);
	const [earnings, setEarnings] = useState(0);
	const [freightsEarnings, setFreightsEarnings] = useState([]);
	const [freightsEarningsAvg, setFreightsEarningsAvg] = useState(0);
	const [instantFreightsEarnings, setInstantFreightsEarnings] = useState([]);
	const [instantFreightsEarningsAvg, setInstantFreightsEarningsAvg] = useState(0);
	const [ordersEarnings, setOrdersEarnings] = useState([]);
	const [ordersEarningsAvg, setOrdersEarningsAvg] = useState(0);
	const [tripsEarnings, setTripsEarnings] = useState([]);
	const [tripsEarningsAvg, setTripsEarningsAvg] = useState(0);
	const [cancelled, setCancelled] = useState(0);
	const [sales, setSales] = useState([]);
	const [loading, setLoading] = useState(true);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const token = useTokenStoreStorage((s) => s.token);
	const userId = useUserIdStorage((s) => s.userId);

	// Fetch earnings function defined outside of useEffect
	const fetchEarnings = async () => {
		try {
			const response = await axios.get(
				`${BASE_URL}admin/list-all-earnings?startDate=${startDate}&endDate=${endDate}`,
				{
					headers: {
						AccessToken: token,
						'Content-Type': 'application/json',
						Cookie: 'XSRF-TOKEN=eyJpdiI6IlpEeWU3a1MyVzA3MXNHNkhJdG5tOFE9PSIsInZhbHVlIjoiZjRLNG9OdmMxekpxTW1UdHgxVkFlL0FJTnd5cklYOEJmcndMUmhvOStxZ3liTTdFaGFPd0dUSnpnNzRvcGFpREVWL0JmWDNCRE5yZXdrVFZsOW13ckl3RzJPRmI0cXdNK2dqQ2tCRkVJdU9OaDZXVDFDSG9NcHZJTSs5SW5RRnciLCJtYWMiOiI4YWQ0OGMyZjNlOGUyMzIwMmYwMGY5ZWY3N2FlOTY5OTkyMzcyZWEzYTFkZjUxNWI0OTQxYWYyMTg5MDM1MjMzIiwidGFnIjoiIn0%3D; laravel_session=eyJpdiI6IkYveEJXQzlyenE3cGhydXY0a2ozZ0E9PSIsInZhbHVlIjoiVlg2bEdIZlNLQVFxMHBhZkg1SW1VU0ZPaGt5SDNKSEN0TWU1ZSs4U0l1azF1VEdhL2xGeXNlclVuODdqTFVlK1BNWlJmRkc1clZibGpSbE03U2JDR0dRUUYvOHpWWG0yQ1BJSndlcTBXeWFEZFBaVFRlNWczdE5TTFlOSHo2d1QiLCJtYWMiOiI0ZTYyYjE5MmZjZWVjNWU0MjFlYjdhMGUxZThiZDlkMDliNTM4ZTRlZTM2ZmU1Nzc4NzQ3ZGVhY2FlMmQwYWEyIiwidGFnIjoiIn0%3D',
					},
					params: {
						user_id: userId,
					},
				},
			);

			setEarnings(response.data);
			setFreightsEarnings(response.data.data.freights_earnings);
			setInstantFreightsEarnings(response.data.data.instant_earnings);
			setFreightsEarningsAvg(response.data.data.averages.average_freight_earnings);
			setInstantFreightsEarningsAvg(response.data.data.averages.average_instant_earnings);
			setTripsEarningsAvg(response.data.data.averages.average_trips_earnings);
			setOrdersEarningsAvg(response.data.data.averages.average_order_earnings);
			setOrdersEarnings(response.data.data.orders_earnings);
			setTripsEarnings(response.data.data.trips_earnings);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const total = freights + bookings + cancelled;
		const bookingPercentage = ((bookings / total) * 100).toFixed(1);
		const completedPercentage = ((completed / total) * 100).toFixed(1);
		const cancelledPercentage = ((cancelled / total) * 100).toFixed(1);
		setBookingNumber(parseFloat(bookingPercentage));
		setCompletedNumber(parseFloat(completedPercentage));
		setCancelledNumber(parseFloat(cancelledPercentage));
	}, [freights, bookings, cancelled]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await axios.get(`${BASE_URL}admin/getDashboardData`, {
					headers: {
						AccessToken: token,
						'Content-Type': 'application/json',
					},
					params: {
						user_id: userId,
					},
				});
				setFreights(response.data.data.sqlBookingGrphList[0].frcnt);
				setBookings(response.data.data.sqlBookingGrphList[0].bookCnt);
				setCancelled(response.data.data.sqlBookingGrphList[0].canCnt);
				setCompleted(response.data.data.sqlBookingGrphList[0].bookCompleted);
				setAllTransporters(response.data.data.userCntList[0].carrCnt);
				setAllCustomers(response.data.data.userCntList[0].cusCnt);
				setSales(response.data.data.salesarray);
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};

		// Fetch data on mount
		fetchData();
		fetchEarnings(); // You can still call fetchEarnings here for the initial load
	}, [token, userId]);

	return (
		<>
			<HomeLayout>
				<div className="pt-10 p-10">
					<div className="grid grid-cols-1 md:grid-cols-2  2xl:grid-cols-5 gap-10">
						<UsersCard allCustomers={allCustomers} allTransporters={allTransporters} loading={loading} />

						<QuoteVsBookings
							freights={freights}
							bookings={bookings}
							cancelled={cancelled}
							completed={completed}
							loading={loading}
						/>
						<BookingRate percentageNumber={bookingNumber} loading={loading} rateName={'Booking'} />
						<BookingRate percentageNumber={completedNumber} loading={loading} rateName={'Completed'} />
						<BookingRate percentageNumber={cancelledNumber} loading={loading} rateName={'Cancelled'} />
						<div className="2xl:col-span-2">
							<DailyQuotes loading={loading} />
						</div>
					</div>
					<div className="mt-10">
						<EarningGraph
							freightsEarnings={freightsEarnings}
							freightsEarningsAvg={freightsEarningsAvg}
							ordersEarnings={ordersEarnings}
							ordersEarningsAvg={ordersEarningsAvg}
							tripsEarnings={tripsEarnings}
							tripsEarningsAvg={tripsEarningsAvg}
							instantFreightsEarnings={instantFreightsEarnings}
							instantFreightsEarningsAvg={instantFreightsEarningsAvg}
							fetchEarnings={fetchEarnings}
							setStartDate={setStartDate}
							setEndDate={setEndDate}
							startDate={startDate}
							endDate={endDate}
						/>
					</div>
					<div className="mt-10">
						<MonthlySummary loading={loading} />
					</div>
				</div>
			</HomeLayout>
		</>
	);
};
