import HomeLayout from '../../components/Layout/HomeLayout';
import { TransporterTable } from '../../components/Users/TransporterTable';

export const Transporter = () => {
	return (
		<HomeLayout>
			<TransporterTable />
		</HomeLayout>
	);
};
