import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { LineChart } from '@mui/x-charts/LineChart';
import React, { useEffect, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { EarningGraphProps } from '../../types/types'; // Adjust the path as needed

export const EarningGraph: React.FC<EarningGraphProps> = ({
	freightsEarnings,
	ordersEarnings,
	tripsEarnings,
	instantFreightsEarnings,
	instantFreightsEarningsAvg,
	freightsEarningsAvg,
	ordersEarningsAvg,
	tripsEarningsAvg,
	fetchEarnings,
	setStartDate,
	setEndDate,
	startDate,
	endDate,
}) => {
	// Function to process the earnings data
	const processEarnings = (earnings: any) => {
		type EarningsItem = { year: string; value: number };

		const parsedEarnings: EarningsItem[] = JSON.parse(JSON.stringify(earnings));
		const data = parsedEarnings.map((item: EarningsItem) => item.value);

		const formattedDates = parsedEarnings.map((item: EarningsItem) => {
			const [year, month] = item.year.split('-');
			const date = new Date(parseInt(year), parseInt(month) - 1);
			return date.toLocaleDateString('en-US', { month: '2-digit', year: '2-digit' });
		});

		return { data, formattedDates };
	};

	const freightsData = processEarnings(freightsEarnings);
	const ordersData = processEarnings(ordersEarnings);
	const tripsData = processEarnings(tripsEarnings);
	const instantFreightsData = processEarnings(instantFreightsEarnings);

	const chartsParams = {
		margin: { bottom: 20, left: 25, right: 5 },
		height: 300,
	};

	const [showInfo, setShowInfo] = useState(false);
	const [showFreights, setShowFreights] = useState(true);
	const [showOrders, setShowOrders] = useState(true);
	const [showTrips, setShowTrips] = useState(true);
	const [showInstantFreights, setShowInstantFreights] = useState(true);
	const [showFreightsAverage, setShowFreightsAverage] = useState(false);
	const [showordersEarningsAvg, setShowordersEarningsAvg] = useState(false);
	const [showTripsEarningsAvg, setShowTripsEarningsAvg] = useState(false);
	const [showInstantFreightsEarningsAvg, setShowInstantFreightsEarningsAvg] = useState(false);

	const handleMouseEnter = () => {
		setShowInfo(true);
	};

	const handleMouseLeave = () => {
		setShowInfo(false);
	};

	const freightsAvgLine = new Array(freightsData.data.length).fill(freightsEarningsAvg);
	const storeDeliveriesAvgLine = new Array(ordersData.data.length).fill(ordersEarningsAvg);
	const tripsAvgLine = new Array(tripsData.data.length).fill(tripsEarningsAvg);
	const instantFreightsAvgLine = new Array(instantFreightsData.data.length).fill(instantFreightsEarningsAvg);

	const handleToggleFreights = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowFreights(event.target.checked);
	};

	const handleToggleOrders = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowOrders(event.target.checked);
	};

	const handleToggleTrips = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowTrips(event.target.checked);
	};

	const handleToggleInstantFreights = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowInstantFreights(event.target.checked);
	};

	const handleToggleFreightsAverage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowFreightsAverage(event.target.checked);
	};
	const handleToggleStoreDeliveriesAverage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowordersEarningsAvg(event.target.checked);
	};
	const handleToggleTripsAverage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowTripsEarningsAvg(event.target.checked);
	};
	const handleToggleInstantFreightsAverage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShowInstantFreightsEarningsAvg(event.target.checked);
	};

	// Calculate the current month and 6 months ago dynamically
	useEffect(() => {
		const today = new Date();
		const currentYear = today.getFullYear();
		const currentMonth = today.getMonth() + 1; // getMonth is zero-indexed

		// Calculate the end date (current month)
		const formattedEndDate = `${currentYear}-${String(currentMonth).padStart(2, '0')}`;
		setEndDate(formattedEndDate);

		// Calculate the start date (6 months before the current date)
		const sixMonthsAgo = new Date(today.setMonth(today.getMonth() - 5));
		const startYear = sixMonthsAgo.getFullYear();
		const startMonth = sixMonthsAgo.getMonth() + 1;
		const formattedStartDate = `${startYear}-${String(startMonth).padStart(2, '0')}`;
		setStartDate(formattedStartDate);
	}, []);

	// Handlers for date range inputs
	const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStartDate(event.target.value);
	};

	const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEndDate(event.target.value);
	};

	// Simulating data fetching from server based on date range
	const handleFetchData = () => {
		fetchEarnings();
	};

	const LoadingText = styled('text')(({ theme }) => ({
		stroke: 'none',
		fill: theme.palette.text.primary,
		shapeRendering: 'crispEdges',
		textAnchor: 'middle',
		dominantBaseline: 'middle',
	}));

	function LoadingOverlay() {
		return <LoadingText>Loading data ...</LoadingText>;
	}

	return (
		<div>
			<div className="rounded-md bg-white rounded-b flex flex-col justify-between leading-normal shadow-md overflow-x-auto 2xl:overflow-hidden">
				<div className="pt-2 pl-4 h-10 bg-gray-800 flex rounded-t justify-between align-middle">
					<p className="pr-2 text-white">
						<b>Earning Graph</b>
					</p>
					<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="w-10">
						<FaInfoCircle color="white" size={20} className="mr-3" />
						{showInfo && (
							<div className="bg-white shadow-2xl rounded-md p-2 w-72 border border-gray-300 absolute -mt-20 -ml-64">
								This card shows the Earnings the business did every month
							</div>
						)}
					</div>
				</div>

				{/* Date Range Selection */}
				<div className="p-4">
					<label>Start Date: </label>
					<input
						type="month"
						value={startDate}
						onChange={handleStartDateChange}
						min="2020-01"
						max={endDate} // Ensure start date can't be after the end date
					/>
					<label className="ml-4">End Date: </label>
					<input
						type="month"
						value={endDate}
						onChange={handleEndDateChange}
						min={startDate} // Ensure end date can't be before the start date
					/>
					<button onClick={handleFetchData} className="ml-4 p-2 bg-blue-500 text-white rounded">
						Fetch Data
					</button>
				</div>

				{/* Checkbox to toggle series */}
				<div className="p-4">
					<h2>Earnings</h2>
					<FormControlLabel
						control={<Checkbox checked={showFreights} onChange={handleToggleFreights} color="primary" />}
						label="Freights"
					/>
					<FormControlLabel
						control={<Checkbox checked={showOrders} onChange={handleToggleOrders} color="primary" />}
						label="Store Deliveries"
					/>
					<FormControlLabel
						control={<Checkbox checked={showTrips} onChange={handleToggleTrips} color="primary" />}
						label="Trips"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={showInstantFreights}
								onChange={handleToggleInstantFreights}
								color="primary"
							/>
						}
						label="Instant Freights"
					/>
					<div>
						<h2>Averages</h2>
						<FormControlLabel
							control={
								<Checkbox
									checked={showFreightsAverage}
									onChange={handleToggleFreightsAverage}
									color="primary"
								/>
							}
							label="Freights Average"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={showordersEarningsAvg}
									onChange={handleToggleStoreDeliveriesAverage}
									color="primary"
								/>
							}
							label="Store Deliveries Average"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={showTripsEarningsAvg}
									onChange={handleToggleTripsAverage}
									color="primary"
								/>
							}
							label="Trips Average"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={showInstantFreightsEarningsAvg}
									onChange={handleToggleInstantFreightsAverage}
									color="primary"
								/>
							}
							label="Instant Freights Average"
						/>
					</div>
				</div>

				<Stack direction="column" spacing={2} alignItems="center" sx={{ width: '100%' }}>
					<LineChart
						grid={{ vertical: true, horizontal: true }}
						{...chartsParams}
						slotProps={{
							noDataOverlay: { message: 'Loading data...' },
						}}
						xAxis={[
							{
								data: freightsData.formattedDates,
								scaleType: 'band',
								dataKey: 'month',
							},
						]}
						series={[
							...(showFreights
								? [
										{
											data: freightsData.data,
											color: '#1878b5',
											label: 'Freights Earnings',
										},
								  ]
								: []),
							...(showFreightsAverage
								? [
										{
											data: freightsAvgLine,
											color: '#71aacf',
											label: 'Freights Average',
											id: 'freights-average-line',
											showMark: false,
										},
								  ]
								: []),
							...(showordersEarningsAvg
								? [
										{
											data: storeDeliveriesAvgLine,
											color: '#e57373',
											label: 'Store Deliveries Average',
											id: 'storeDeliveries-average-line',
											showMark: false,
										},
								  ]
								: []),
							...(showTripsEarningsAvg
								? [
										{
											data: tripsAvgLine,
											color: '#4caf50',
											label: 'Trips Average',
											id: 'trips-average-line',
											showMark: false,
										},
								  ]
								: []),
							...(showInstantFreightsEarningsAvg
								? [
										{
											data: instantFreightsAvgLine,
											color: '#ade1e1',
											label: 'Instant Freights Average',
											id: 'instants-average-line',
											showMark: false,
										},
								  ]
								: []),
							...(showOrders
								? [
										{
											data: ordersData.data,
											color: '#9e3e3e',
											label: 'Store Deliveries Earnings',
										},
								  ]
								: []),
							...(showTrips
								? [
										{
											data: tripsData.data,
											color: '#3c653e',
											label: 'Trips Earnings',
										},
								  ]
								: []),
							...(showInstantFreights
								? [
										{
											data: instantFreightsData.data,
											color: '#22DDDA',
											label: 'Instant Earnings',
										},
								  ]
								: []),
						]}
					/>
				</Stack>
			</div>
		</div>
	);
};
