import { Button } from '@mui/material';
import { GridValueGetter } from '@mui/x-data-grid';

const idValueGetter: GridValueGetter = (value, row) => row.id ?? 'undefined';
const priceValueGetter: GridValueGetter = (value, row) => row.estimated_price ?? 'undefined';
const dateValueGetter: GridValueGetter = (value, row) => row.created_at ?? 'undefined';
const originValueGetter: GridValueGetter = (value, row) =>
	[row.origin_address, row.origin_city, row.origin_state].filter(Boolean).join(', ') || 'undefined';
const destinationValueGetter: GridValueGetter = (value, row) =>
	[row.destination_address, row.destination_city, row.destination_state].filter(Boolean).join(', ') || 'undefined';

const columns = (handleDetailsButton: any) => [
	{ field: 'id', headerName: 'ID', width: 40, valueGetter: idValueGetter },
	{
		field: 'Status',
		headerName: 'Status',
		flex: 1,
		renderCell: (params: any) => {
			// Define valid button colors based on state
			let buttonProps: { color: 'primary' | 'info' | 'success' | 'error' | 'warning'; label: string } = {
				color: 'warning', // Default button color
				label: 'Unknown',
			};

			switch (params.row.status) {
				case 'created':
					buttonProps = { color: 'warning', label: 'Created' };
					break;
				case 'confirmed':
					buttonProps = { color: 'info', label: 'Confirmed' };
					break;
				default:
					buttonProps = { color: 'warning', label: 'Unknown' };
					break;
			}

			return (
				<Button variant="contained" color={buttonProps.color} style={{ width: '100%', cursor: 'default' }}>
					{buttonProps.label}
				</Button>
			);
		},
	},
	{
		field: 'Client name',
		headerName: 'Client Name',
		flex: 1,
		renderCell: (params: any) =>
			params?.row?.customer ? (
				<Button variant="outlined" onClick={() => handleDetailsButton('customer', params.row.customer.id)}>
					{`${params?.row?.customer?.firstname || 'N/A'} ${params?.row?.customer?.lastname || ''}`}
				</Button>
			) : (
				'N/A'
			),
	},
	{
		field: 'Carrier name',
		headerName: 'Carrier Name',
		flex: 1,
		renderCell: (params: any) =>
			params?.row?.carrier ? (
				<Button variant="outlined" onClick={() => handleDetailsButton('carrier', params.row.carrier.id)}>
					{`${params?.row?.carrier?.firstname || 'N/A'} ${params?.row?.carrier?.lastname || ''}`}
				</Button>
			) : (
				'N/A'
			),
	},
	{ field: 'Price', headerName: 'Price', width: 70, flex: 1, valueGetter: priceValueGetter },
	{ field: 'dates', headerName: 'Date Start', width: 170, flex: 1, valueGetter: dateValueGetter },
	{ field: 'origin', headerName: 'Origin', width: 200, flex: 1, valueGetter: originValueGetter },
	{
		field: 'destination',
		headerName: 'Destination',
		width: 200,
		flex: 1,
		valueGetter: destinationValueGetter,
	},
];

export default columns;
