import Stack from '@mui/material/Stack';
import { BarChart } from '@mui/x-charts/BarChart/BarChart';
import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { UsersCardProps } from '../../types/types'; // Adjust the path as needed

export const UsersCard: React.FC<UsersCardProps> = ({ allCustomers, allTransporters, loading }) => {
	const [showInfo, setShowInfo] = useState(false);

	const handleMouseEnter = () => {
		setShowInfo(true);
	};

	const handleMouseLeave = () => {
		setShowInfo(false);
	};

	return (
		<div>
			<div className="rounded-md bg-white rounded-b flex flex-col justify-between leading-normal shadow-md">
				<div className="bg-gray-800 flex rounded-t items-center justify-between h-10">
					<p className="pl-4  text-white">
						<b>Total Users:</b>
					</p>

					<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="w-10">
						<FaInfoCircle color="white" size={20} className="mr-3" />
						{showInfo && (
							<div className="bg-white  shadow-2xl rounded-md p-2 w-72 border border-gray-300 absolute -mt-10 -ml-64">
								This graphic shows the number of customers and transporters using the app
							</div>
						)}
					</div>
				</div>
				<Stack direction={{ md: 'row', xs: 'column' }} sx={{ width: '100%' }}>
					<BarChart
						slotProps={{
							noDataOverlay: { message: 'No data to display in this chart' },
						}}
						loading={loading}
						// slots={{ loadingOverlay: LoadingOverlay }}
						yAxis={[
							{
								id: 'barCategories',
								data: ['Trans', 'Cust'],
								scaleType: 'band',
							},
						]}
						series={[
							{
								data: [allTransporters, allCustomers],
								color: '#649f26',
							},
						]}
						layout="horizontal"
						width={320}
						height={150}
					/>
				</Stack>
			</div>
		</div>
	);
};
