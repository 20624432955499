// columns.ts

import { Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { GridValueGetter } from '@mui/x-data-grid';

// Define value getters
const idValueGetter: GridValueGetter = (value, row) => {
	const id = row.id;
	return id !== undefined && id !== null ? id : 'undefined';
};

const originValueGetter: GridValueGetter = (value, row) => row.origin || 'undefined';
const destinationValueGetter: GridValueGetter = (value, row) => row.destination || 'undefined';
const runnmePriceValueGetter: GridValueGetter = (value, row) => Number(row.price) || 'undefined';
const companyValueGetter: GridValueGetter = (value, row) => row.company || 'undefined';
const createDateValueGetter: GridValueGetter = (value, row) => row.created_at || 'undefined';
const updateDateValueGetter: GridValueGetter = (value, row) => row.updated_at || 'undefined';
const expirationDateValueGetter: GridValueGetter = (value, row) => row.expiration_date || 'undefined';

const columns = (handleDetailsButton: any, handleOpenModal: any) => [
	{ field: 'id', headerName: 'ID', width: 40, valueGetter: idValueGetter },
	{
		field: 'Status',
		headerName: 'Status',
		flex: 1,
		renderCell: (params: any) => {
			let buttonProps: { color: 'primary' | 'info' | 'success' | 'error' | 'warning'; label: string } = {
				color: 'warning',
				label: 'Unknown',
			};

			switch (params.row.state) {
				case 0:
					buttonProps = { color: 'warning', label: 'Active' };
					break;
				case 1:
					buttonProps = { color: 'info', label: 'Assigned' };
					break;
				case 2:
					buttonProps = { color: 'success', label: 'Completed' };
					break;
				case 3:
					return (
						<Button
							variant="contained"
							style={{
								backgroundColor: grey[500],
								color: '#fff',
								width: '100%',
							}}
						>
							Expired
						</Button>
					);
				default:
					buttonProps = { color: 'warning', label: 'Unknown' };
					break;
			}

			return (
				<Button variant="contained" color={buttonProps.color} style={{ width: '100%', cursor: 'default' }}>
					{buttonProps.label}
				</Button>
			);
		},
	},
	{
		field: 'Client name',
		headerName: 'Client Name',
		flex: 1,
		renderCell: (params: any) =>
			params?.row?.customer ? (
				<Button variant="outlined" onClick={() => handleDetailsButton('customer', params.row.customer.id)}>
					{`${params?.row?.customer?.firstname || 'N/A'} ${params?.row?.customer?.lastname || ''}`}
				</Button>
			) : (
				'N/A'
			),
	},
	{
		field: 'Carrier name',
		headerName: 'Carrier Name',
		flex: 1,
		renderCell: (params: any) =>
			params?.row?.carrier ? (
				<Button variant="outlined" onClick={() => handleDetailsButton('carrier', params.row.carrier.id)}>
					{`${params?.row?.carrier?.firstname || 'N/A'} ${params?.row?.carrier?.lastname || ''}`}
				</Button>
			) : (
				'N/A'
			),
	},
	{ field: 'Origin', headerName: 'Origin', flex: 1, valueGetter: originValueGetter },
	{ field: 'Destination', headerName: 'Destination', flex: 1, valueGetter: destinationValueGetter },
	{ field: 'Price', headerName: 'Price', width: 40, valueGetter: runnmePriceValueGetter },
	{ field: 'Company', headerName: 'Company', flex: 1, valueGetter: companyValueGetter },
	{ field: 'Create date', headerName: 'Create Date', flex: 1, valueGetter: createDateValueGetter },
	{ field: 'Update date', headerName: 'Update Date', flex: 1, valueGetter: updateDateValueGetter },
	{ field: 'Expiration date', headerName: 'Expiration Date', flex: 1, valueGetter: expirationDateValueGetter },
	{
		field: 'action',
		headerName: 'Action',
		flex: 1,
		renderCell: (params: any) => (
			<Button variant="contained" color="error" onClick={() => handleOpenModal(params.row)}>
				Archive
			</Button>
		),
	},
];

export default columns;
