import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { MonthlySummaryProps } from '../../types/types'; // Adjust the path as needed

export const MonthlySummary: React.FC<MonthlySummaryProps> = ({ loading }) => {
	function createData(month: string, matchingFees: number, sales: number, numListing: number, numListingWC: number) {
		return { month, matchingFees, sales, numListing, numListingWC };
	}

	const rows = [
		createData('January', 159, 6.0, 24, 4.0),
		createData('February', 237, 9.0, 37, 4.3),
		createData('March', 262, 16.0, 24, 6.0),
		createData('April', 305, 3.7, 67, 4.3),
		createData('May', 356, 16.0, 49, 3.9),
		createData('June', 356, 16.0, 49, 3.9),
		createData('Luly', 356, 16.0, 49, 3.9),
		createData('August', 356, 16.0, 49, 3.9),
		createData('September', 356, 16.0, 49, 3.9),
		createData('October', 356, 16.0, 49, 3.9),
		createData('November', 356, 16.0, 49, 3.9),
		createData('December', 356, 16.0, 49, 3.9),
	];

	const [showInfo, setShowInfo] = useState(false);

	const handleMouseEnter = () => {
		setShowInfo(true);
	};

	const handleMouseLeave = () => {
		setShowInfo(false);
	};

	return (
		<div>
			<div className=" rounded-md bg-white rounded-b flex flex-col justify-between leading-normal shadow-md">
				<div className="pt-2 pl-4 h-10 bg-gray-800 flex justify-between rounded-t  align-middle">
					<p className="pr-2 text-white">
						<b> Monthly Summary </b>
					</p>
					<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="w-10">
						<FaInfoCircle color="white" size={20} className="mr-3" />
						{showInfo && (
							<div className="bg-white  shadow-2xl rounded-md p-2 w-72 border border-gray-300 absolute -mt-20 -ml-64">
								This table shows all the data from every month in the Freightseek app
							</div>
						)}
					</div>
				</div>
				{loading ? (
					<div className="text-center m-20">Loading...</div>
				) : (
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell>Month</TableCell>
									<TableCell align="right" sx={{ width: '150px' }}>
										Matching Fees
									</TableCell>
									<TableCell align="right">Sales </TableCell>
									<TableCell align="right" sx={{ width: '130px' }}>
										Num Listing{' '}
									</TableCell>
									<TableCell align="right" sx={{ width: '200px' }}>
										Num Listing With Quotes{' '}
									</TableCell>
									<TableCell align="right" sx={{ width: '220px' }}>
										Num Listing Without Quotes{' '}
									</TableCell>
									<TableCell align="right" sx={{ width: '130px' }}>
										Num Bookings{' '}
									</TableCell>
									<TableCell align="right" sx={{ width: '130px' }}>
										Booking Rate{' '}
									</TableCell>
									<TableCell align="right" sx={{ width: '130px' }}>
										Total Cancels{' '}
									</TableCell>
									<TableCell align="right" sx={{ width: '130px' }}>
										Cancel %{' '}
									</TableCell>
									<TableCell align="right" sx={{ width: '130px' }}>
										Total Quotes{' '}
									</TableCell>
									<TableCell align="right" sx={{ width: '130px' }}>
										Quotes Value{' '}
									</TableCell>
									<TableCell align="right">Quotes Value (Avg) </TableCell>
									<TableCell align="right"> Total Reviews </TableCell>
									<TableCell align="right"> New Transporters </TableCell>
									<TableCell align="right"> New Customers </TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row) => (
									<TableRow
										key={row.month}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row">
											{row.month}
										</TableCell>
										<TableCell align="right">{row.matchingFees}</TableCell>
										<TableCell align="right">{row.sales}</TableCell>
										<TableCell align="right">{row.numListing}</TableCell>
										<TableCell align="right">{row.numListingWC}</TableCell>
										<TableCell align="right">{row.numListingWC}</TableCell>
										<TableCell align="right">{row.numListingWC}</TableCell>
										<TableCell align="right">{row.numListingWC}</TableCell>
										<TableCell align="right">{row.numListingWC}</TableCell>
										<TableCell align="right">{row.numListingWC}</TableCell>
										<TableCell align="right">{row.numListingWC}</TableCell>
										<TableCell align="right">{row.numListingWC}</TableCell>
										<TableCell align="right">{row.numListingWC}</TableCell>
										<TableCell align="right">{row.numListingWC}</TableCell>
										<TableCell align="right">{row.numListingWC}</TableCell>
										<TableCell align="right">{row.numListingWC}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</div>
		</div>
	);
};
