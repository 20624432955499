import { Box, Button, Grid, Modal, Portal } from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarQuickFilter, GridValueGetter } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';

import axios from 'axios';
import React from 'react';
import { BASE_URL } from '../../api';
import { useTokenStoreStorage, useUserIdStorage } from '../../hooks/useTokenStore';

function MyCustomToolbar(props: any) {
	return (
		<React.Fragment>
			<Portal container={() => document.getElementById('filter-panel')!}>
				<GridToolbarQuickFilter />
			</Portal>
			<GridToolbar {...props} />
		</React.Fragment>
	);
}

export const TransporterTable = () => {
	const [data, setData] = React.useState([]); // Estado para almacenar los datos del servidor
	const [openModal, setOpenModal] = React.useState(false);
	const [selectedRow, setSelectedRow] = React.useState<any>(null); // Especifica el tipo de selectedRow
	const token = useTokenStoreStorage((s) => s.token);
	const userId = useUserIdStorage((s) => s.userId);
	const handleOpenModal = (row: any) => {
		setSelectedRow(row);
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const fetchData = async () => {
		try {
			const response = await axios.get(`${BASE_URL}admin/allCarrier`, {
				headers: {
					AccessToken: token,
					'Content-Type': 'application/json',
				},
				params: {
					user_id: userId,
				},
			});
			return response.data.data.datas;
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const {
		data: dataset,
		isLoading,
		error,
	} = useQuery({
		queryKey: ['orders'],
		queryFn: fetchData,
	});

	const handleDelete = async () => {
		try {
			await axios.delete(`${BASE_URL}freight/delete`, {
				headers: {
					AccessToken: token,
				},
				params: {
					user_id: userId,
					freight_id: selectedRow.id,
				},
			});
			fetchData();
			setOpenModal(false); // Cerrar modal después de la eliminación exitosa
		} catch (error) {
			console.error('Error deleting data:', error);
		}
	};

	const idValueGetter: GridValueGetter = (value, row) => {
		const id = row.id;

		// Verificar si 'id' existe y no es null
		if (id !== undefined && id !== null) {
			return id;
		} else {
			return 'undefined';
		}
	};
	const usernameValueGetter: GridValueGetter = (value, row) => {
		const username = row.username;

		// Verificar si 'id' existe y no es null
		if (username !== undefined && username !== null) {
			return username;
		} else {
			return 'undefined';
		}
	};
	const emailValueGetter: GridValueGetter = (value, row) => {
		const email = row.email;

		// Verificar si 'id' existe y no es null
		if (email !== undefined && email !== null) {
			return email;
		} else {
			return 'undefined';
		}
	};
	const phoneNumberValueGetter: GridValueGetter = (value, row) => {
		const phoneNumber = row.phoneNumber;

		// Verificar si 'id' existe y no es null
		if (phoneNumber !== undefined && phoneNumber !== null) {
			return phoneNumber;
		} else {
			return 'undefined';
		}
	};
	const app_statusValueGetter: GridValueGetter = (value, row) => {
		const app_status = row.app_status;

		// Verificar si 'id' existe y no es null
		if (app_status !== undefined && app_status !== null) {
			return app_status;
		} else {
			return 'undefined';
		}
	};
	const company_nameValueGetter: GridValueGetter = (value, row) => {
		const company_name = row.company_name;

		// Verificar si 'id' existe y no es null
		if (company_name !== undefined && company_name !== null) {
			return company_name;
		} else {
			return 'undefined';
		}
	};
	const doc1ValueGetter: GridValueGetter = (value, row) => {
		const doc1 = row.doc1;

		// Verificar si 'id' existe y no es null
		if (doc1 !== undefined && doc1 !== null) {
			return '192.168.1.84:8080/storage/app/' + doc1;
		} else {
			return 'undefined';
		}
	};

	const signup_dateValueGetter: GridValueGetter = (value, row) => {
		const signup_date = row.signup_date;

		// Verificar si 'id' existe y no es null
		if (signup_date !== undefined && signup_date !== null) {
			return signup_date;
		} else {
			return 'undefined';
		}
	};

	const columns = React.useMemo(
		() => [
			{ field: 'id', flex: 1, headerName: 'ID', width: 70, valueGetter: idValueGetter },
			{ field: 'username', flex: 1, headerName: 'Username', width: 170, valueGetter: usernameValueGetter },
			{ field: 'email', flex: 1, headerName: 'Email', width: 220, valueGetter: emailValueGetter },
			{
				field: 'phoneNumber',
				flex: 1,
				headerName: 'Phone Number',
				width: 150,
				valueGetter: phoneNumberValueGetter,
			},
			{ field: 'App status', flex: 1, headerName: 'App Status', width: 100, valueGetter: app_statusValueGetter },
			{
				field: 'Compaany Name',
				flex: 1,
				headerName: 'Company Name',
				width: 150,
				valueGetter: company_nameValueGetter,
			},
			{
				field: 'Signup Date',
				flex: 1,
				headerName: 'Signup Date',
				width: 150,
				valueGetter: signup_dateValueGetter,
			},
			{ field: 'doc1', flex: 1, headerName: 'Document 1', width: 150, valueGetter: doc1ValueGetter },

			{
				field: 'action',
				flex: 1,
				headerName: 'Action',
				renderCell: (params: any) => (
					<Button variant="contained" color="error" onClick={() => handleOpenModal(params.row)}>
						Delete
					</Button>
				),
			},
		],
		[],
	);

	const gridReturn = () => (
		<Grid container spacing={2}>
			<Grid item>
				<Box id="filter-panel" />
			</Grid>
			<Grid item style={{ height: 'auto', width: '100%' }}>
				<DataGrid
					rows={dataset || []}
					columns={columns}
					slots={{ toolbar: MyCustomToolbar }}
					autoHeight={true}
					loading={isLoading}
					initialState={{
						pagination: { paginationModel: { pageSize: 5 } },
					}}
					pageSizeOptions={[5, 10, 25]}
					slotProps={{
						loadingOverlay: {
							variant: 'linear-progress',
							noRowsVariant: 'skeleton',
						},
					}}
				/>
			</Grid>
			<Modal open={openModal} onClose={handleCloseModal}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
					}}
				>
					<p className="mb-6 text-center"> Are You sure to DELETE?</p>
					<div className="flex justify-between">
						<Button onClick={handleDelete} color="error" variant="contained" sx={{ marginRight: 3 }}>
							Delete
						</Button>
						<Button onClick={handleCloseModal} color="primary" variant="contained">
							Cancel
						</Button>
					</div>
				</Box>
			</Modal>
		</Grid>
	);

	return (
		<>
			<h1 className="m-3">Admin Transporters</h1>
			<div className="m-10">{gridReturn()}</div>
		</>
	);
};
