import { atom } from "jotai";

export const user_email = atom<string>('')
export const user_token = atom<string>('');



export const user_id = atom<number>(0);
export const booking_number = atom<number>(0); 
export const transporters = atom<number>(0); 
export const customers = atom<number>(0); 

export const sales_array = atom([]);






