import { Box, Button, Grid, Modal, Portal } from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarQuickFilter, GridValueGetter } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useEffect } from 'react';
import { BASE_URL } from '../../api';
import { useTokenStoreStorage, useUserIdStorage } from '../../hooks/useTokenStore';
function MyCustomToolbar(props: any) {
	return (
		<React.Fragment>
			<Portal container={() => document.getElementById('filter-panel')!}>
				<GridToolbarQuickFilter />
			</Portal>
			<GridToolbar {...props} />
		</React.Fragment>
	);
}

export const AdminTable = () => {
	const [data, setData] = React.useState([]); // Estado para almacenar los datos del servidor
	const [openModal, setOpenModal] = React.useState(false);
	const [selectedRow, setSelectedRow] = React.useState<any>(null);
	const token = useTokenStoreStorage((s) => s.token);
	const handleOpenModal = (row: any) => {
		setSelectedRow(row);
		setOpenModal(true);
	};
	const userId = useUserIdStorage((s) => s.userId);
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	const fetchData = async () => {
		try {
			const response = await axios.get(`${BASE_URL}admin/allAdmin`, {
				headers: {
					AccessToken: token,
					'Content-Type': 'application/json',
				},
				params: {
					user_id: userId,
				},
			});
			return response.data.data.datas;
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const {
		data: dataset,
		isLoading,
		error,
	} = useQuery({
		queryKey: ['orders'],
		queryFn: fetchData,
	});

	const handleDelete = async () => {
		try {
			await axios.delete(`${BASE_URL}freight/delete`, {
				headers: {
					AccessToken: token,
				},
				params: {
					user_id: userId,
					freight_id: selectedRow.id,
				},
			});
			console.log('Data deleted successfully.');
			fetchData();
			setOpenModal(false); // Cerrar modal después de la eliminación exitosa
		} catch (error) {
			console.error('Error deleting data:', error);
		}
	};

	useEffect(() => {
		fetchData(); // Carga los datos al montar el componente
	}, []);
	const idValueGetter: GridValueGetter = (value, row) => {
		const id = row.id;

		// Verificar si 'id' existe y no es null
		if (id !== undefined && id !== null) {
			return id;
		} else {
			return 'undefined';
		}
	};

	// Función para obtener el valor de la columna 'status'
	const statusValueGetter: GridValueGetter = (value, row) => {
		const status = row.status;

		// Verificar si 'status' existe y no es null
		if (status !== undefined && status !== null) {
			return status;
		} else {
			return 'undefined';
		}
	};

	const created_atValueGetter: GridValueGetter = (value, row) => {
		const created_at = row.created_at;

		// Verificar si 'status' existe y no es null
		if (created_at !== undefined && created_at !== null) {
			return created_at;
		} else {
			return 'undefined';
		}
	};

	const emailValueGetter: GridValueGetter = (value, row) => {
		const email = row.email;
		// Verificar si 'status' existe y no es null
		if (email !== undefined && email !== null) {
			return email;
		} else {
			return 'undefined';
		}
	};
	const firstnameValueGetter: GridValueGetter = (value, row) => {
		const firstname = row.firstname;
		// Verificar si 'status' existe y no es null
		if (firstname !== undefined && firstname !== null) {
			return firstname;
		} else {
			return 'undefined';
		}
	};

	const lastnameValueGetter: GridValueGetter = (value, row) => {
		const lastname = row.lastname;
		// Verificar si 'status' existe y no es null
		if (lastname !== undefined && lastname !== null) {
			return lastname;
		} else {
			return 'undefined';
		}
	};
	const usernameValueGetter: GridValueGetter = (value, row) => {
		const username = row.username;
		// Verificar si 'status' existe y no es null
		if (username !== undefined && username !== null) {
			return username;
		} else {
			return 'undefined';
		}
	};

	const phoneNumberValueGetter: GridValueGetter = (value, row) => {
		const phoneNumber = row.phoneNumber;
		// Verificar si 'status' existe y no es null
		if (phoneNumber !== undefined && phoneNumber !== null) {
			return phoneNumber;
		} else {
			return 'undefined';
		}
	};

	const quotesEndValueGetter: GridValueGetter = (value, row) => {
		const qcnt = row.qcnt;
		// Verificar si 'status' existe y no es null
		if (qcnt !== undefined && qcnt !== null) {
			return [qcnt + ' Active'];
		} else {
			return 'undefined';
		}
	};
	const viewsEndValueGetter: GridValueGetter = (value, row) => {
		const views = row.views;
		// Verificar si 'status' existe y no es null
		if (views !== undefined && views !== null) {
			return [views + ' Views'];
		} else {
			return 'undefined';
		}
	};

	// Definir las columnas personalizadas
	const columns = React.useMemo(
		() => [
			{ field: 'id', flex: 1, headerName: 'ID', width: 70, valueGetter: idValueGetter },
			{ field: 'status', flex: 1, headerName: 'Status', valueGetter: statusValueGetter },
			{ field: 'Created At', flex: 1, headerName: 'Created At', valueGetter: created_atValueGetter },
			{ field: 'email', flex: 1, headerName: 'Email', width: 200, valueGetter: emailValueGetter },
			{ field: 'FirstName', flex: 1, headerName: 'First Name', width: 200, valueGetter: firstnameValueGetter },
			{ field: 'LastName', flex: 1, headerName: 'Last Name', width: 170, valueGetter: lastnameValueGetter },
			{ field: 'Username', flex: 1, headerName: 'Username', width: 170, valueGetter: usernameValueGetter },
			{ field: 'PhoneNumber', flex: 1, headerName: 'Phone Number', valueGetter: phoneNumberValueGetter },
			{
				field: 'action',
				flex: 1,
				headerName: 'Action',
				renderCell: (params: any) => (
					<Button variant="contained" color="error" onClick={() => handleOpenModal(params.row)}>
						Delete
					</Button>
				),
			},
		],
		[],
	);
	const fetchDataFreight = async () => {
		try {
			const response = await axios.get(`${BASE_URL}admin/allFreight`, {
				headers: {
					AccessToken: token,
					'Content-Type': 'application/json',
				},
				params: {
					user_id: userId,
				},
			});

			// Actualizar el estado 'data' con los datos del servidor
			setData(response.data.data.datas);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const gridReturn = () => (
		<Grid container spacing={2}>
			<Grid item>
				<Box id="filter-panel" />
			</Grid>
			<Grid item style={{ height: 'auto', width: '100%' }}>
				<DataGrid
					rows={dataset || []}
					columns={columns}
					slots={{ toolbar: MyCustomToolbar }}
					autoHeight={true}
					loading={isLoading}
					initialState={{
						pagination: { paginationModel: { pageSize: 5 } },
					}}
					pageSizeOptions={[5, 10, 25]}
					slotProps={{
						loadingOverlay: {
							variant: 'linear-progress',
							noRowsVariant: 'skeleton',
						},
					}}
				/>
			</Grid>
			<Modal open={openModal} onClose={handleCloseModal}>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: 400,
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
					}}
				>
					<p className="mb-6 text-center"> Are You sure to DELETE?</p>
					<div className="flex justify-between">
						<Button onClick={handleDelete} color="error" variant="contained" sx={{ marginRight: 3 }}>
							Delete
						</Button>
						<Button onClick={handleCloseModal} color="primary" variant="contained">
							Cancel
						</Button>
					</div>
				</Box>
			</Modal>
		</Grid>
	);

	return (
		<>
			<h1 className="m-3">Admin Table</h1>
			<div className="m-10">{gridReturn()}</div>
		</>
	);
};
