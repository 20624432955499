import { Navigate, Outlet } from 'react-router-dom';
import { useTokenStoreStorage } from '../hooks/useTokenStore';

const ProtectedRoute = () => {
	const token = useTokenStoreStorage((s) => s.token);

	if (token === '') {
		return <Navigate to={'/login'} replace />;
	}

	return <Outlet />;
};

export default ProtectedRoute;
