
export let BASE_URL = `http://192.168.1.170:8080/api/`
export let BASE_IMG_URL = ``
let prod = 2

if (prod === 0) {
    BASE_URL = `http://192.168.1.170:8080/api/`
    BASE_IMG_URL = `http://192.168.1.170:8080/storage/app/public/category_images/`

} else if (prod === 1) {
    BASE_URL = `https://api.runnme.com/api/`
    BASE_IMG_URL = `https://api.runnme.com/api/storage/app/public/category_images/`
} else if (prod === 2) {
    BASE_URL = `https://apitest.runnme.com/api/`
    BASE_IMG_URL = `https://apitest.runnme.com/api/storage/app/public/category_images/`
}
else if (prod === 3) {
BASE_IMG_URL = `http://192.168.1.170:8080/storage/app/public/category_images/`
    BASE_URL = `http://192.168.1.170:8080/api/`
}